import { OperationsService } from './../../services/operations.service';
// import { EspecieControl } from './../../models/maestros';
import { ModalEspecieComponent } from './../modal-especie/modal-especie.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLinkActive, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import { ModalMostrarEspeciesComponent } from '../modal-mostrar-especies/modal-mostrar-especies.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';


@Component({
  selector: 'app-edit-control',
  templateUrl: './edit-control.component.html',
  styleUrls: ['./edit-control.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class EditControlComponent implements OnInit {

  control: any;
  fecha: string;

  cofradiasFiltradas: Observable<any>;
  cofradias: any[];
  cofradiaCtrl = new FormControl();
  cofradiasSubs: Subscription;

  columnas = ['nombre', 'fao', 'cantidad', 'banco', 'editar', 'borrar'];
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private location: Location,
    public dialog: MatDialog,
    private operations: OperationsService,
  ) { }

  ngOnInit(): void {
    this.control = history.state.data.row;
    console.log("COMPONENT EDIT CONTROL onInit()  control cargado .... \n ", this.control);

    this.dataSource = new MatTableDataSource<any>(this.control.especies.filter(e => e.cantidad > 0));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;


    // Setea valor de cofradia en autocomplete
    this.cofradiaCtrl.setValue(this.control.cofradia.nombre);

    // MAESTROS 
    // set cofradias
    this.cofradiasSubs = this.api.getCofradias().subscribe(
      (data: any) => {
        if (data.exito) {
          this.cofradias = data.cofradias;
          this.cofradiasFiltradas = this.cofradiaCtrl.valueChanges
            .pipe(
              startWith(''),
              map(c => c ? this.filtrarItems(c, this.cofradias) : this.cofradias.slice())
            )
        }
      },
      error => { console.log("LISTA CONTROLES ngOnInit() getConfradias error .... ", error); }

    )

    this.operations.especieSeteada.subscribe(
      (especie: any) => {
        console.log("EDIT CONTROL especie seteada .... ", especie)
        if (especie.index || especie.index === 0) {
          this.control.especies[especie.index] = especie
          this.control.especies = this.control.especies.filter((e: any) => e.id != especie.former)
        } else {
          if (this.control.especies === null) this.control.especies = [];
          this.control.especies.push(especie);
        }
        console.log("EDIT CONTROL especies ..... ", this.control.especies)
        this.dataSource = new MatTableDataSource<any>(this.control.especies.filter(e => e.cantidad > 0));
      }
    )

    this.fecha = moment(this.control.data).format("DD-MM-YYYY");

  } // fin onInit()

  onEnter(evt: any) {
    if (evt.source.selected) {
    }
  }
  private filtrarItems(value: string, items: any[]): any {
    const filterValue = value.toLowerCase();
    return items.filter(item => item.nombre.toLowerCase().indexOf(filterValue) === 0);
  }


  selectCofradiaFromAutocomplete(cofradia) {
    console.log(cofradia)
    this.control.cofradia = cofradia;
  }

  //  TABLA ESPECIES
  editarEspecie(row: any, index: number) {
    let titulo = "Editar especie";
    console.log(titulo, index, row)
    const dialogRef = this.dialog.open(ModalEspecieComponent, { data: [titulo, index, row], width: '800px' })

    dialogRef.afterClosed().subscribe(result => {
      console.log("Especie de dialogo .... ", result)
    })

  }

  borrarEspecie(row: any, index: number) {
    this.control.especies = this.control.especies.filter((e) => e.id != row.id);
    this.dataSource = new MatTableDataSource<any>(this.control.especies.filter((e) => e.cantidad > 0));
  }

  // BOTONNERA 
  agregarEspecie() {
    let titulo = "Agregar especie"
    const dialogRef = this.dialog.open(ModalEspecieComponent, { data: [titulo, null, null], width: '800px' })
  }

  cancelar() {
    this.location.back();
  }
  guardarControl() {
    let params = { user: JSON.parse(localStorage.getItem('usuario')), control: this.control };
    console.log("COMPONENT EDIT CONTROL guardarControl() control .... \n", this.control)
    this.api.updateControl(params).subscribe(
      (data: any) => {
        if (data.exito) {
          alert("Se han guardado los cambios.")
          console.log("COMPONENT EDIT CONTROL guardarControl() exito .... ", data);
          this.router.navigate(['/main']);
        } else {
          alert("Se ha producido un error.");
          console.log("COMPONENT EDIT CONTROL guardarControl() se ha producido un error .... ", data);
        }
      },
      error => {
        alert("Se ha producido un error")
        console.log("COMPONENT EDIT CONTROL guardarControl() error .... ", error);
      }
    );

  }

}
