<div class="wrapper">
  <div class="formulario">
    <mat-card class="controles">
      <mat-card-header>
        <mat-card-title>Criterios de búsqueda</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br>
        <form class="formulario-opciones" autocomplete="off" (keydown.enter)="$event.preventDefault()">

          <!-- FILTRO DE RANGO DE FECHAS  -->
          <mat-form-field appearance="outline">
            <mat-label>Rango de fechas</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Inicio" />
              <input matEndDate formControlName="end" placeholder="Fin" (dateInput)="selectRange()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha inicial no válida</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha final no válida</mat-error>
          </mat-form-field>

          <!-- AUTOCOMPLETE DE VIGILANTE 
          (onSelectionChange)="selectVigilanteFromAutocomplete(vigilante)"-->
          <!-- <mat-form-field appearance="outline" (click)="inputVigilante.select()">
            <mat-label>Vigilante</mat-label>
            <input #inputVigilante type="text" aria-label="Number" matInput [formControl]="vigilanteCtrl"
              [matAutocomplete]="auto" (blur)="resetearFiltro($event, 'vigilante')" >
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='selectVigilanteFromAutocomplete($event.option.value)'>
              <mat-option *ngFor="let vigilante of vigilantesFiltrados | async"
                [value]=" vigilante.descripcion.toUpperCase()">
                <span> {{vigilante.descripcion | uppercase}} </span>
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-user" class="formulario"></mat-icon>
          </mat-form-field> -->

          <!-- AUTOCOMPLETE DE EMBARCARCIÓN -->
          <mat-form-field class="no-padding-bottom full-width mat-input-disabled-custom" appearance="outline" (click)="inputEmbarcacion.select()">
            <mat-label>Buque</mat-label>
            <input type="text" matInput #inputEmbarcacion [matAutocomplete]="embarcacion"
              [formControl]="embarcacionCtrl" (blur)="resetearFiltro($event, 'embarcacion')" />
            <mat-autocomplete autoActiveFirstOption #embarcacion="matAutocomplete" (optionSelected)='selectEmbarcacionFromAutocomplete($event.option.value)'>
              <mat-option *ngFor="let embarcacion of embarcacionesFiltradas | async"
                [value]="embarcacion.nombre.toUpperCase()">
                <span>{{ embarcacion.nombre | uppercase}} ({{ embarcacion.matricula | uppercase}})</span>
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-ship" class="formulario"></mat-icon>
          </mat-form-field>

          <!-- AUTOCOMPLETE CONFRADIA -->
          <!-- <mat-form-field appearance="outline">
            <mat-label>Cofradía (venta)</mat-label>
            <input type="text" matInput #inputCofradia [matAutocomplete]="cofradia" [formControl]="cofradiaCtrl"
              (keydown)="resetearFiltro($event)" />
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-home" class="formulario"></mat-icon>
            <mat-autocomplete autoActiveFirstOption #cofradia="matAutocomplete">
              <mat-option *ngFor="let cofradia of cofradiasFiltradas | async" [value]="cofradia.nombre"
                (onSelectionChange)="
                  inputCofradia.value != undefined && onEnter($event);
                  selectCofradiaFromAutocomplete(cofradia)
                ">
                <span>{{ cofradia.nombre }} ({{ cofradia.codigo }})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->

          <!--AUTOCOMPLETE BANCO  -->
          <mat-form-field appearance="outline" (click)="inputBanco.select()">
            <mat-label>Banco</mat-label>
            <input type="text" matInput #inputBanco [matAutocomplete]="banco" [formControl]="bancoCtrl"
              (blur)="resetearFiltro($event, 'banco')" />
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-map-marker-alt" class="formulario"></mat-icon>
            <mat-autocomplete autoActiveFirstOption #banco="matAutocomplete" (optionSelected)='selectBancoFromAutocomplete($event.option.value)'>
              <mat-option *ngFor="let banco of bancosFiltrados | async" [value]="banco.nombre.toUpperCase()">
                <span>{{ banco.nombre | uppercase}} ({{ banco.codigo | uppercase}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- AUTOCOMPLETE DE ESPECIE -->
          <mat-form-field class="no-padding-bottom full-width mat-input-disabled-custom" appearance="outline" (click)="inputEspecie.select()">
            <mat-label>Especie</mat-label>
            <input type="text" matInput #inputEspecie [matAutocomplete]="especie"
              [formControl]="especieCtrl" (blur)="resetearFiltro($event, 'especie')" />
            <mat-autocomplete autoActiveFirstOption #especie="matAutocomplete" (optionSelected)='selectEspecieFromAutocomplete($event.option.value)'>
              <mat-option *ngFor="let especie of especiesFiltradas | async"
                [value]="especie.nombre.toUpperCase()">
                <span>{{ especie.nombre | uppercase}} ({{ especie.fao | uppercase}})</span>
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-tags" class="formulario"></mat-icon>
          </mat-form-field>


          <mat-form-field class="no-padding-bottom full-width mat-input-disabled-custom" appearance="outline" (click)="inputBuscar.select()">
            <mat-label>Ticket</mat-label>
            <input type="text" matInput #inputBuscar
              [formControl]="buscarCtrl" (blur)="resetearFiltro($event, 'buscar')" />
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-search" class="formulario"></mat-icon>
          </mat-form-field>

          <button mat-raised-button class="formulario-opciones-boton" (click)="clear()">
            <mat-icon>clear</mat-icon> &nbsp; Limpiar
          </button>
          <button mat-raised-button class="formulario-opciones-boton" (click)="descargarExcel()">
            <mat-icon>get_app</mat-icon> &nbsp; Descargar excel
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <mat-card class="controles">
      <mat-card-header>
        <mat-card-title>Lista de controles</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort class="lista-controles" *ngIf="controles" (matSortChange)="sortData($event)">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef style="padding-right: 10px;">
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            
            <!-- <ng-container matColumnDef="usuario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                VIGILANTE
              </th>
              <td mat-cell *matCellDef="let control">
                {{ control.usuario.descripcion | uppercase }}
              </td>
            </ng-container> -->

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                TICKET
              </th>
              <td mat-cell *matCellDef="let control">
                {{ control.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="embarcacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                BARCO
              </th>
              <td mat-cell *matCellDef="let control">
                {{ control.embarcacion.nombre }} ({{ control.embarcacion.matricula }})
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="dia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>DIA</th>
              <td mat-cell *matCellDef="let control">
                {{ getDayFromStringDate(control.fecha) }}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="entrada">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ENTRADA</th>
              <td mat-cell *matCellDef="let control">{{ control.horaActividad }}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="salida">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>SALIDA</th>
              <td mat-cell *matCellDef="let control">{{ control.hora }}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- <ng-container matColumnDef="cofradia">
              <th mat-header-cell *matHeaderCellDef>COFRADÍA</th>
              <td mat-cell *matCellDef="let control">
                {{ control.cofradia.nombre }}
              </td>
            </ng-container> -->


            <ng-container matColumnDef="tripulacion">
              <th mat-header-cell *matHeaderCellDef class="number" mat-sort-header>TRIP.</th>
              <td mat-cell *matCellDef="let control" class="number">
                {{ control.tripulantes }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="banco">
              <th mat-header-cell *matHeaderCellDef style="max-width: 10vw;" mat-sort-header>BANCO</th>
              <td mat-cell *matCellDef="let control" style="max-width: 10vw;">
                <!-- {{ showBancos(control.bancos) }} -->
                <div *ngFor="let b of control.bancos">{{b.nombre}}</div>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="babosa">
              <th mat-header-cell *matHeaderCellDef class="number" mat-sort-header>BABOSA (Kg)</th>
              <td mat-cell *matCellDef="let control" class="number"> {{ control.BABOSA }} </td>
            </ng-container>

            <ng-container matColumnDef="fina">
              <th mat-header-cell *matHeaderCellDef class="number" mat-sort-header> FINA (Kg)</th>
              <td mat-cell *matCellDef="let control" class="number"> {{ control.FINA }} </td>
            </ng-container>

            <ng-container matColumnDef="japonica">
              <th mat-header-cell *matHeaderCellDef class="number" mat-sort-header>JAPONICA (Kg)</th>
              <td mat-cell *matCellDef="let control" class="number"> {{ control.JAPONICA }} </td>
            </ng-container>

            <ng-container matColumnDef="berberecho">
              <th mat-header-cell *matHeaderCellDef class="number" mat-sort-header>BERBERECHO (Kg)</th>
              <td mat-cell *matCellDef="let control" class="number"> {{ control.BERBERECHO }} </td>
            </ng-container>

            <ng-container matColumnDef="birollo">
              <th mat-header-cell *matHeaderCellDef class="number" mat-sort-header>BIROLLO (Kg)</th>
              <td mat-cell *matCellDef="let control" class="number"> {{ control.BIROLLO }} </td>
            </ng-container>

            <ng-container matColumnDef="especies">
              <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
              <td mat-cell *matCellDef="let control">
                <button mat-raised-button class="formulario-opciones-boton-ver" title="Ver especies" (click)="mostrarEspecies(control)">
                  <mat-icon matPrefix fontSet="fas" fontIcon="fa-tags" style="margin-top: 0.7vh"></mat-icon>
                  VER
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- <ng-container matColumnDef="editar">
              <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
              <td mat-cell *matCellDef="let row" (click)="editarControl(row)" class="td-icon td-icon-blue">
                <button mat-raised-button class="button-blue" title="Editar control">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="borrar">
              <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
              <td mat-cell *matCellDef="let row" (click)="borrarControl(row)" title="Borrar control">
                <button mat-raised-button class="button-red">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container> -->


            <!-- subheader -->
            <ng-container matColumnDef="subheader">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.includes('dia') ? '5' : '4'"></th>
            </ng-container>

            <ng-container matColumnDef="total-tripulacion">
              <th mat-header-cell *matHeaderCellDef class="header number">
                {{ getTotal()}} </th>
            </ng-container>
            <ng-container matColumnDef="subheader2">
              <th mat-header-cell *matHeaderCellDef colspan="1"></th>
            </ng-container>

            <ng-container matColumnDef="total-babosa">
              <th mat-header-cell *matHeaderCellDef class="header number">
                {{ getTotalWeight('BABOSA')}} </th>
            </ng-container>

            <ng-container matColumnDef="total-fina">
              <th mat-header-cell *matHeaderCellDef class="header number">
                {{ getTotalWeight('FINA')}} </th>
            </ng-container>

            <ng-container matColumnDef="total-japonica">
              <th mat-header-cell *matHeaderCellDef class="header number">
                {{ getTotalWeight('JAPONICA')}} </th>
            </ng-container>

            <ng-container matColumnDef="total-berberecho">
              <th mat-header-cell *matHeaderCellDef class="header number">
                {{ getTotalWeight('BERBERECHO')}} </th>

            </ng-container>
            <ng-container matColumnDef="total-birollo">
              <th mat-header-cell *matHeaderCellDef class="header number">
                {{ getTotalWeight('BIROLLO')}} </th>
            </ng-container>

            <ng-container matColumnDef="subheader-end">
              <th mat-header-cell *matHeaderCellDef colspan="3"> </th>
            </ng-container>


            <!-- configuración de tabla  -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-header-row
              *matHeaderRowDef="['subheader', 'total-tripulacion' , 'subheader2' , 'total-babosa', 'total-fina', 'total-japonica', 'total-berberecho', 'total-birollo','subheader-end']">
            </tr>
            <tr mat-row [ngClass]="row.especies ? '' : 'sin-especies'" *matRowDef="let row; columns: displayedColumns">
            </tr>
          </table>
          <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
