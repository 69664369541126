import { Injectable, EventEmitter } from '@angular/core';
import { EspecieControl } from '../models/maestros';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  especieSeteada = new EventEmitter<any>();
  constructor() { }
}
