import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class MainComponent implements OnInit {

  title = 'Cofradía de pescadores de Rianxo'
  usuarioLogueado: any;

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.usuarioLogueado = this.auth.getUsuarioLogueado()
  }

  irABayppas() {
    this.router.navigate(['opt'])
  }

  logOut() {
    this.auth.logOut();

  }
}
