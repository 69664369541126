import { EspecieControl } from '../../models/maestros';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';



@Component({
  selector: 'app-modal-especies',
  templateUrl: './modal-mostrar-especies.component.html',
  styleUrls: ['./modal-mostrar-especies.component.css']
})
export class ModalMostrarEspeciesComponent implements OnInit {

  columnas = ['nombre', 'fao', 'cantidad', 'banco'];

  especies: EspecieControl[]
  control: any;
  fecha: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.control = data;
  }

  ngOnInit(): void {
    this.fecha = moment(this.control.data).format('DD-MM-YYYY')
    this.especies = this.control.especies.filter(e => e.cantidad > 0);
    console.log("MODAL ESPECIES onInit() this.especies .... ", this.especies);
  }

}
