<div class="wrapper">
    <!-- formulario -->
    <div class="formulario">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    {{tituloFormulario}}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <div class="formulario-row-id">
                        <mat-form-field class="input-id mat-input-disabled-custom">
                            <input #inputId matInput disabled [formControl]="idCtrl" [(ngModel)]="formularioId">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-nombre">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input #inputNombre matInput [formControl]="nombreCtrl" [(ngModel)]="formularioNombre"
                                placeholder="Nombre de usuario">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-datos-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Nombre Completo</mat-label>
                            <input #inputNombreCompleto matInput [formControl]="nCompletoCtrl"
                                [(ngModel)]="formularioNCompleto" placeholder="Nombre completo del usuario">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-datos-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input #inputEmail matInput [formControl]="emailCtrl"
                                [(ngModel)]="formularioEmail" placeholder="Email del usuario">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-datos-1">
                        <mat-form-field [class]="edit? 'mat-input-disabled-custom full-width' : 'full-width'" appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input #inputPass matInput [formControl]="passCtrl" [readonly]="edit" [class]="edit? 'mat-input-disabled-custom' : ''"
                                [(ngModel)]="formularioPass" placeholder="Contraseña del usuario">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-entidades">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Rol</mat-label>
                            <mat-select #selectRol [formControl]="rolCtrl">
                                <mat-option *ngFor="let rol of roles" [value]="rol.id" (onSelectionChange)="selectRoles(rol)"
                                    >{{rol.tipo}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="formulario-row-observaciones">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Observaciones</mat-label>
                            <textarea #observaciones matInput [formControl]="observacionesCtrl"
                                [(ngModel)]="formularioObservaciones" placeholder="Observaciones"> </textarea>
                        </mat-form-field>
                    </div>


                    <div class="formulario-row-estado">
                        <label id="radio-group-label-estado"> Estado del usuario</label>
                        <mat-radio-group #radioGroupEstado aria-labelledby="example-radio-group-label"
                            class="radio-group-estado" [formControl]="estadoCtrl" [(ngModel)]="formularioEstado">
                            <mat-radio-button class="radio-button-estado-option"
                                *ngFor="let estado of formularioEstados" [value]="estado.id">
                                {{estado.nombre}}
                            </mat-radio-button>

                        </mat-radio-group>

                    </div>

                    <div class="formulario-row-botonera">
                        <button mat-button mat-raised-button class="button-red" (click)="cancelar()">
                            <mat-icon>clear</mat-icon> Limpiar
                        </button>
                        <button mat-button mat-raised-button class="button-blue" (click)="guardarUsuario()">
                            <mat-icon>done</mat-icon> Guardar
                        </button>
                    </div>


                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- lista -->
    <div class="lista-usuarios">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Lista de usuarios
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="filtros-lista-usuarios">
                    <!-- AUTOCOMPLETE DE EMBARCARCIÓN -->
                    <mat-form-field class="no-padding-bottom filtro-usuario" appearance="outline" (click)="inputUsuario.select()">
                        <mat-label>Usuario</mat-label>
                        <input type="text" matInput #inputUsuario [matAutocomplete]="usuario"
                            [formControl]="usuarioCtrl" (blur)="resetearFiltro($event)" />
                        <mat-autocomplete autoActiveFirstOption #usuario="matAutocomplete" (optionSelected)='selectUsuarioFromAutocomplete($event.option.value)'>
                            <mat-option *ngFor="let usuario of usuariosFiltradas | async"
                                [value]="usuario.nombre.toUpperCase()">
                                <span>{{ usuario.nombre | uppercase}} ({{ usuario.codigo | uppercase}})</span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix fontSet="fas" fontIcon="fa-ship" class="formulario"></mat-icon>
                    </mat-form-field>

                    <!-- TOGGLE DE ESTADO -->
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value=1 #gro="matButtonToggleGroup">
                        <mat-button-toggle value=1 (click)="setFiltroEstado(1)"> Activos</mat-button-toggle>
                        <mat-button-toggle value=2 (click)="setFiltroEstado(2)">Baja</mat-button-toggle>
                        <mat-button-toggle value=0 (click)="setFiltroEstado(0)">Todos</mat-button-toggle>
                    </mat-button-toggle-group>

                </div>
                <div class="lista-usuarios-2">
                    <table mat-table [dataSource]="dataSource" matSort class="lista-usuarios-tabla">

                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                NOMBRE
                            </th>
                            <td mat-cell *matCellDef="let usuario">
                                {{ usuario.nombre }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="nombreCompleto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                NOMBRE COMPLETO
                            </th>
                            <td mat-cell *matCellDef="let usuario">
                                {{ usuario.nombreCompleto }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="editar">
                            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                            <td mat-cell *matCellDef="let row" (click)="editarUsuario(row)"
                                class="td-icon td-icon-blue">
                                <button mat-raised-button class="button-blue" title="Editar embarcación">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="borrar">
                            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                            <td mat-cell *matCellDef="let row" title="Dar de baja">
                                <button *ngIf="row.estado === 1" mat-raised-button class="button-red" (click)="borrarUsuario(row)">
                                    <mat-icon>delete_forever</mat-icon>
                                </button>
                                <button *ngIf="row.estado === 2" mat-raised-button class="button-green" (click)="activarUsuario(row)">
                                    <mat-icon>check_circle_outline</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->

                        <tr mat-row [ngClass]="row.estado === 2 ? 'baja ' : ''"
                            *matRowDef="let row; columns: displayedColumns"></tr>

                    </table>


                    <mat-paginator #paginator [pageSizeOptions]="[12, 25, 100]" [showFirstLastButtons]="true">
                    </mat-paginator>

                </div>
            </mat-card-content>
        </mat-card>



    </div>
</div>