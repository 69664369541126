import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './guards/auth-guads.service';
import { ListaControlesComponent } from './main/lista-controles/lista-controles.component';
import { EditControlComponent } from './main/edit-control/edit-control.component';
import { BypassComponent } from './bypass/bypass.component';
import { AdminBuquesComponent } from './main/admin-buques/admin-buques.component';
import { AdminEspeciesComponent } from './main/admin-especies/admin-especies.component';
import { AdminBancosComponent } from './main/admin-bancos/admin-bancos.component';
import { AdminUsuariosComponent } from './main/admin-usuarios/admin-usuarios.component';


const routes: Routes = [
  { path: '', redirectTo: 'main/controles', pathMatch: 'full',
  canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent},
  { path: 'opt', component: BypassComponent }, //, canActivate: [AuthGuard] },
  {
    path: 'main', component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ListaControlesComponent },
      { path: 'controles', component: ListaControlesComponent },
      { path: 'editar-control', component: EditControlComponent },
      { path: 'buque', component: AdminBuquesComponent },
      { path: 'especie', component: AdminEspeciesComponent },
      { path: 'banco', component: AdminBancosComponent },
      { path: 'usuario', component: AdminUsuariosComponent },
      { path: '**', component: LoginComponent }
    ]
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
