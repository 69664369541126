import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { ListaControlesComponent } from './lista-controles/lista-controles.component';
import { MainComponent } from './main.component';
import { EditControlComponent } from './edit-control/edit-control.component';
import { AdminBuquesComponent } from './admin-buques/admin-buques.component';

const mainRoutes: Routes = [
     {
          path: 'main', component: MainComponent,
          children: [
               { path: '', component: ListaControlesComponent },
               { path: 'controles', component: ListaControlesComponent },
               { path: 'editar-control', component: EditControlComponent },
               { path: 'buques', component: AdminBuquesComponent },
               { path: '**', component: LoginComponent }
          ]
     },
     { path: '**', component: LoginComponent }


];

@NgModule({
     imports: [
          RouterModule.forChild(mainRoutes)
     ],
     exports: [RouterModule]
})

export class MainRoutingModule {

}