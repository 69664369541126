import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from '../main/modal-message/modal-message.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = 'Cofradía de pescadores de Rianxo'

  @ViewChild('pass') inputPass: ElementRef;
  @ViewChild('entrar') botonEntrar: ElementRef;

  username: string = '';
  password: string = '';
  mostrarPassword = false;
  botonEntrarDeshabilitado = true;

  constructor(
    private auth: AuthService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  login() {
    if (this.username === '' || this.password === '') {
      alert("Debe indicar un usuario y una contraseña");
      return;
    }

    this.auth.checkUser(this.username, this.password).subscribe(
      data => {
        console.log(data)
        if (data.code === 1) {
          this.auth.setUsuarioLogueado(data.usuario);
          this.auth.setSettings(data.usuario.settings);
          if (data) {
            setTimeout(
              () => {
                this.router.navigate(['main/controles'])
              }, 100
            );
          }


          // this.auth.getToken().subscribe(
          //   data => {
          //   }
          // )

        } else {
          if (data.msg) {
            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: data.msg,
              width: '500px',
            });
            // alert(`${data.msg}`);
          }
        }
      },
      error => console.log(error)
    )
  }

  toggleType() {
    if (this.mostrarPassword) {
      this.mostrarPassword = false;
    } else {
      this.mostrarPassword = true;
    }
  }
  setFocus(id: string) {
    console.log("setFocus() id .... ", id)
    if (id === 'pass') this.inputPass.nativeElement.focus()
  }

  habilitarBotonEntrar() {
    if (this.password != '' && this.username != '') {
      if (this.password.length >= 3) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
    
  }

}
