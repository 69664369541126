import { Injectable } from '@angular/core';
import { ENV_CONFIG, SAMPLING_SERVICE_CONFIG, LOGS } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  /** Devuelve una constante de entorno */
  getENV_CONFIG(key: string) {
    return ENV_CONFIG[key];
  }
  /** Devuelve una ruta para el servicio */
  getSERVICE_CALL(service: string) {
    return SAMPLING_SERVICE_CONFIG[service];
  }
  /** Devuelve nombre de cliente de applicación */
  getUSER_NAME() {
    return ENV_CONFIG.CLIENT_NAME;
  }
  /** Devuelve  apikey  */
  getAPI_KEY() {
    return ENV_CONFIG.API_KEY;
  }
  /** Devuelve url base */
  getURI() {
    return SAMPLING_SERVICE_CONFIG.BASE_URL;
  }
  /** Devulve estado del log de componente */
  getLOG(component: string) {
    return LOGS[component];
  }
}
