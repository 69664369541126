import {
  SelectionModel
} from "@angular/cdk/collections";
import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {
  FormControl,
  FormGroup
} from "@angular/forms";
import {
  DateAdapter
} from "@angular/material/core";
import {
  MatDialog
} from "@angular/material/dialog";
import {
  MatPaginator
} from "@angular/material/paginator";
import {
  MatSort,
  Sort
} from "@angular/material/sort";
import {
  MatTableDataSource
} from "@angular/material/table";
import {
  Router
} from "@angular/router";
import * as moment from "moment";
import {
  Observable,
  Subscription
} from "rxjs";
import {
  map,
  startWith
} from "rxjs/operators";
import {
  ApiService
} from "../../services/api.service";
import {
  LoadingExcelDialogComponent
} from '../loading-excel-dialog/loading-excel-dialog.component';
import { ModalMessageComponent } from "../modal-message/modal-message.component";
import {
  ModalMostrarEspeciesComponent
} from "../modal-mostrar-especies/modal-mostrar-especies.component";
import {
  Vigilante
} from "./../../models/maestros";



@Component({
  selector: "app-lista-controles",
  templateUrl: "./lista-controles.component.html",
  styleUrls: ["./lista-controles.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ListaControlesComponent implements OnInit {
  controles: any[];
  controlesMaestros: any[];
  controlesSubs: Subscription;

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });

  // flag para mostrar la columna de fecha en caso de seleccionar un rango
  showDay = false;


  vigilanteCtrl = new FormControl();
  vigilantesFiltrados: Observable < Vigilante[] > ;
  vigilantes: Vigilante[]
  vigilanteSubs: Subscription;


  embarcacionesFiltradas: Observable < any > ;
  embarcaciones: any[];
  embarcacionCtrl = new FormControl();
  embarcacionesSubs: Subscription;

  especiesFiltradas: Observable < any > ;
  especies: any[];
  especieCtrl = new FormControl();
  especiesSubs: Subscription;

  cofradiasFiltradas: Observable < any > ;
  cofradias: any[];
  cofradiaCtrl = new FormControl();
  cofradiasSubs: Subscription;

  buscarCtrl = new FormControl();

  bancosFiltrados: Observable < any > ;
  bancos: any[];
  bancoCtrl = new FormControl();
  bancosSubs: Subscription;


  displayedColumns: string[] = ["select", "id", "embarcacion", "entrada", "tripulacion", "banco", "babosa", "fina", "japonica", "berberecho", "birollo", "especies"];


  selection = new SelectionModel < any > (true, []);
  dataSource; // = new MatTableDataSource(this.controles);
  //dataSource = new MatTableDataSource<any>(this.controles);
  @ViewChild(MatPaginator, {
    static: true
  }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filters = {}


  constructor(
    // private controlesService: ControlesService,
    private dateAdapter: DateAdapter < Date > ,
    private api: ApiService,
    public dialog: MatDialog,
    private router: Router,
    private inj: Injector
  ) {}

  ngOnInit(): void {

    // CONFIG DATETIME
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };


    // SETEOS DE MAESTROS
    // SET VIGILANTES
    this.api.getVigilantes().subscribe(
      (data: any) => {
        if (data.exito) {
          this.vigilantes = data.vigilantes;
          this.vigilantesFiltrados = this.vigilanteCtrl.valueChanges.pipe(
            startWith(''),
            map(value => this.filtrarVigilantes(value))
          )
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getEmbarcaciones error .... ", error);
      }
    );

    // SETEO DE EMBARCIONES
    this.embarcacionesSubs = this.api.getEmbarcaciones().subscribe(
      (data: any) => {
        if (data.exito) {
          this.embarcaciones = data.buques;
          this.embarcacionesFiltradas = this.embarcacionCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarEmbarcaciones(value))
            )
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getEmbarcaciones error .... ", error);
      }
    );

    // SETEO DE ESPECIES
    this.especiesSubs = this.api.getEspecies(1).subscribe(
      (data: any) => {
        if (data.exito) {
          this.especies = data.especies;
          this.especiesFiltradas = this.especieCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarEspecies(value))
            )
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getEspecies error .... ", error);
      }
    );

    // SETEO DE CONFRADIAS
    this.cofradiasSubs = this.api.getCofradias().subscribe(
      (data: any) => {
        if (data.exito) {
          this.cofradias = data.cofradias;
          this.cofradiasFiltradas = this.cofradiaCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarCofradias(value))
            );
        }
      },
      (error) => {
        console.log(
          "LISTA CONTROLES ngOnInit() getCofradias error .... ", error);
      }
    );
    // set bancos
    this.bancosSubs = this.api.getBancos().subscribe(
      (data: any) => {
        // TODO refactorizar metodo en  api
        if (data.exito) {
          this.bancos = data.bancos;
          this.bancosFiltrados = this.bancoCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarBancos(value))
            );

        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getBancos error .... ", error);
      }
    );

    //GET CONTROLES
    this.getControles();

  } // FIN ON INIT()


  // FUNCIONES DE FILTRO 
  filtrarVigilantes(value: string) {
    const filterValue = value.toLowerCase();
    return this.vigilantes.filter(vigilante => vigilante.descripcion.toLowerCase().includes(filterValue));
  }

  filtrarEmbarcaciones(value: string) {
    const filterValue = value.toLowerCase();
    return this.embarcaciones.filter(embarcacion => embarcacion.nombre.toLowerCase().includes(filterValue));
  }

  filtrarEspecies(value: string) {
    const filterValue = value.toLowerCase();
    return this.especies.filter(especie => especie.nombre.toLowerCase().includes(filterValue));
  }

  filtrarCofradias(value: string) {
    const filterValue = value.toLowerCase();
    return this.cofradias.filter(cofradia => cofradia.nombre.toLowerCase().includes(filterValue));
  }
  filtrarBancos(value: string) {
    const filterValue = value.toLowerCase();
    return this.bancos.filter(banco => banco.nombre.toLowerCase().includes(filterValue));
  }

  // CONTROLES FILTRO
  // changeDate(event) {
  //   let fecha = event.value,
  //     params;
  //   fecha = moment(fecha).format("YYYY-MM-DD");
  //   if (fecha) {
  //     params = { finicio: fecha, final: fecha };
  //   }
  //   // console.log("chageDate() params", params)
  //   this.getControles(params);
  // }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.controles = this.controles.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'embarcacion':
          return this.compare(a.embarcacion.nombre, b.embarcacion.nombre, isAsc);
        case 'dia':
          return this.compare(a.fecha + " " + a.hora, b.fecha + " " + b.hora, isAsc);
        case 'entrada':
          return this.compare(a.fecha + " " + a.hora, b.fecha + " " + b.hora, isAsc);
        case 'banco':
          return this.compare(this.showBancos(a.bancos), this.showBancos(b.bancos), isAsc);
        case 'tripulacion':
          return this.compare(a.tripulantes, b.tripulantes, isAsc);
        case 'babosa':
          return this.compare(a.BABOSA, b.BABOSA, isAsc);
        case 'fina':
          return this.compare(a.FINA, b.FINA, isAsc);
        case 'berberecho':
          return this.compare(a.BERBERECHO, b.BERBERECHO, isAsc);
        case 'birollo':
          return this.compare(a.BIROLLO, b.BIROLLO, isAsc);
        case 'japonica':
          return this.compare(a.JAPONICA, b.JAPONICA, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource < any > (this.controles);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }



  selectRange() {
    this.selection.clear()
    let params;
    if (this.range.value.end) {
      params = {
        finicio: moment(this.range.value.start).format("YYYY-MM-DD"),
        final: moment(this.range.value.end).format("YYYY-MM-DD")
      }
      if (params.finicio != params.final) {
        this.displayedColumns = ["select", "id", "embarcacion", "dia", "entrada", "tripulacion", "banco", "babosa", "fina", "japonica", "berberecho", "birollo", "especies"];
      } else {
        this.displayedColumns = ["select", "id", "embarcacion", "entrada", "tripulacion", "banco", "babosa", "fina", "japonica", "berberecho", "birollo", "especies"];
      }
      this.getControles(params)
    }
  }

  selectVigilanteFromAutocomplete(vigilante) {
    this.selection.clear()
    if (vigilante) {
      this.filters['vigilante'] = vigilante
    }
    if (this.controles) {
      if (this.filters['vigilante'])
        this.controles = this.controles.filter((c) => c.usuario.descripcion.toUpperCase() === this.filters['vigilante']);
      if (this.filters['embarcacion'])
        this.controles = this.controles.filter((c) => c.embarcacion.nombre.toUpperCase() === this.filters['embarcacion']);
      if (this.filters['banco'])
        this.controles = this.controles.filter((c) => c.bancos.map((item) => item.nombre).includes(this.filters['banco']));
      if (this.filters['especie'])
        this.controles = this.controles.filter((c) => c[this.filters['especie']] != "--");
      this.dataSource = new MatTableDataSource < any > (this.controles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

  }

  selectEmbarcacionFromAutocomplete(embarcacion) {
    this.selection.clear()
    if (embarcacion) {
      this.filters['embarcacion'] = embarcacion
    }
    if (this.controles) {
      if (this.filters['vigilante'])
        this.controles = this.controles.filter((c) => c.usuario.descripcion.toUpperCase() === this.filters['vigilante']);
      if (this.filters['embarcacion'])
        this.controles = this.controles.filter((c) => c.embarcacion.nombre.toUpperCase() === this.filters['embarcacion']);
      if (this.filters['banco'])
        this.controles = this.controles.filter((c) => c.bancos.map((item) => item.nombre).includes(this.filters['banco']));
      if (this.filters['especie'])
        this.controles = this.controles.filter((c) => c[this.filters['especie']] != "--");
      this.dataSource = new MatTableDataSource < any > (this.controles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

  }

  selectEspecieFromAutocomplete(especie) {
    this.selection.clear()
    if (especie) {
      this.filters['especie'] = especie
    }
    if (this.controles) {
      if (this.filters['vigilante'])
        this.controles = this.controles.filter((c) => c.usuario.descripcion.toUpperCase() === this.filters['vigilante']);
      if (this.filters['embarcacion'])
        this.controles = this.controles.filter((c) => c.embarcacion.nombre.toUpperCase() === this.filters['embarcacion']);
      if (this.filters['banco'])
        this.controles = this.controles.filter((c) => c.bancos.map((item) => item.nombre).includes(this.filters['banco']));
      if (this.filters['especie'])
        this.controles = this.controles.filter((c) => c[this.filters['especie']] != "--");
      this.dataSource = new MatTableDataSource < any > (this.controles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

  }

  selectCofradiaFromAutocomplete(cofradia) {
    this.controles = this.controles.filter(
      (c) => c.cofradia.id === cofradia.id
    );
    this.dataSource = new MatTableDataSource < any > (this.controles);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  selectBancoFromAutocomplete(banco) {
    this.selection.clear()
    if (banco) {
      this.filters['banco'] = banco
    }
    if (this.controles) {
      if (this.filters['vigilante'])
        this.controles = this.controles.filter((c) => c.usuario.descripcion.toUpperCase() === this.filters['vigilante']);
      if (this.filters['embarcacion'])
        this.controles = this.controles.filter((c) => c.embarcacion.nombre.toUpperCase() === this.filters['embarcacion']);
      if (this.filters['banco'])
        this.controles = this.controles.filter((c) => c.bancos.map((item) => item.nombre).includes(this.filters['banco']));
      if (this.filters['especie'])
        this.controles = this.controles.filter((c) => c[this.filters['especie']] != "--");
      this.dataSource = new MatTableDataSource < any > (this.controles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  resetearFiltro(event, name) {
    this.selection.clear()
    if (event.target.value.length <= 3) {
      delete this.filters[name]
      if (this.controlesMaestros) {
        this.controles = this.controlesMaestros
        if (this.filters['vigilante'])
          this.controles = this.controles.filter((c) => c.usuario.descripcion.toUpperCase() === this.filters['vigilante']);
        if (this.filters['embarcacion'])
          this.controles = this.controles.filter((c) => c.embarcacion.nombre.toUpperCase() === this.filters['embarcacion']);
        if (this.filters['banco'])
          this.controles = this.controles.filter((c) => c.bancos.map((item) => item.nombre).includes(this.filters['banco']));
        if (this.filters['especie'])
          this.controles = this.controles.filter((c) => c[this.filters['especie']] != "--");

        this.dataSource = new MatTableDataSource < any > (this.controles);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }



    } else if (name == "buscar"){
      this.filters['buscar'] = event.target.value
      this.controles = this.controles.filter((c) => c.id.includes(this.filters['buscar']));
      this.dataSource = new MatTableDataSource < any > (this.controles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  onEnter(evt: any) {

  }

  // FUNCIONALIDADES TABLA
  /** funcionalidad checkbox */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.controles.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.controles.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row ? : any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
      }`;
  }

  /** Mostrar especies */
  mostrarEspecies(control) {
    let w = control.especies ? "1000px" : "500px";
    const dialogRef = this.dialog.open(ModalMostrarEspeciesComponent, {
      data: control,
      width: w,
    });
  }

  /** editar control  */
  editarControl(row) {
    // navega a formulario de edición pansando objeto completo
    this.router.navigate(["main/editar-control"], {
      state: {
        data: {
          row
        }
      }
    });
  }

  /** Borrar control  */
  borrarControl(row: any) {

    let params = {
      user: JSON.parse(localStorage.getItem('usuario')),
      uid: row.uid
    }
    this.api.deleteControl(params).subscribe(
      (data: any) => {
        alert("Control '" + row.id + "' borrado definitivamente.");
        if (data.exito) {
          // let fecha = moment(this.fechaSolicitada$.value).format("YYYY-MM-DD");
          let fecha = moment().format("YYYY-MM-DD");

          if (fecha != moment().format("YYYY-MM-DD")) {
            let params = {
              finicio: fecha,
              final: fecha
            };

            this.getControles(params);
          } else {
            this.getControles();
          }
        }
      },
      (error) => {
        console.log("LISTA CONTROLRES borrarControl() error .... ", error);
      }
    );
  }


  getDayFromStringDate(date: string) {
    // return `${parseInt(date.substr(8, 9))} / ${parseInt(date.substring(5, 7))}`;
    if (date)
      return moment(date, 'YYYY-MM-DD').format('DD/MM');
    return ''
  }


  // DESCARGAR EXCELL
  descargarExcel() {
    let ids = [],
      idsString: string;

    console.log(
      "LISTA CONTROLES descargarExcel() this.selection ",
      this.selection.selected
    );

    if (this.selection.selected.length === 0) {
      const modal = this.inj.get(MatDialog);
      const dialogRef = modal.open(ModalMessageComponent, {
        data: "Seleccione al menos un elemento",
        width: '500px',
      });
      return;
    }

    this.selection.selected.forEach((r) => ids.push(r.uid));
    console.log("LISTA CONTROLES descargarExcel() ids ", ids);
    
    this.api.getExcelControles({ids}).subscribe(
      (data: any) => {
        if (data.exito) {
          console.log("LISTA CONTROLES descargarExcel() data .... ", data);
          let nombreFichero = data.response.nombre;
          console.log(
            "LISTA CONTROLES descargarExcel() creado fichero .... ",
            nombreFichero
          );
          let ruta = data.response.ruta_web;

          let delayTime = Math.ceil(Math.pow(ids.length, 1.0005))
          let ledRef = this.dialog.open(LoadingExcelDialogComponent);

          console.log("LISTA CONTROLES descargarExcel() ruta .... ", ruta);
          // Hay que darle tiempo a servidor para que escriba el fichero
          // setTimeout(() => {
          //   window.open(ruta);
          //   ledRef.close();
          // }, delayTime >= 500 ? delayTime : 500);
          this.api.downloadExcel(ruta).subscribe((data) => {
            console.log("Excel")
            ledRef.close();
          },
          (error) => {
            console.log(error)
            ledRef.close();
          });

        } else {
          console.log(
            "OPERATIONS SERVICE descargarExcel() durante la creación .... "
          );
        }
      },
      (error) => {
        console.log("LISTA CONTROLES descargarExcel() error ", error);
      }
    );
  }

  // GET CONTROLES
  getControles(params = null) {
    params = params ?
      params : {
        finicio: moment().format("YYYY-MM-DD"),
        final: moment().format("YYYY-MM-DD"),
      };

    this.controlesSubs = this.api.getControles(params).subscribe(
      (data: any) => {
        if (data.exito && data.controles.length > 0) {
          // this.controles = data.controles;
          // this.controlesMaestros = data.controles;

          // refactorización 
          console.log('LISTA CONTROLES getControles() controles data .... ', data);
          this.controlesMaestros = this.mapControls(data.controles);
          this.controles = this.mapControls(data.controles)
          if (this.filters['vigilante'])
            this.controles = this.controles.filter((c) => c.usuario.descripcion.toUpperCase() === this.filters['vigilante']);
          if (this.filters['embarcacion'])
            this.controles = this.controles.filter((c) => c.embarcacion.nombre.toUpperCase() === this.filters['embarcacion']);
          if (this.filters['banco'])
            this.controles = this.controles.filter((c) => c.bancos.map((item) => item.nombre).includes(this.filters['banco']));
          this.dataSource = new MatTableDataSource < any > (this.controles);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
        if (data.exito && data.controles.length === 0) {
          this.controles = [];
          console.log("LISTA CONTROLES getControles()  sin controles .... ");
          this.dataSource = new MatTableDataSource < any > (this.controles);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      },
      (error) => {
        console.log("LISTA CONTROLES getControles() error .... ", error);
      }
    );
  }

  // ON DESTROY
  ngOnDestroy() {
    // this.vigilantesSubs.unsubscribe();
    this.embarcacionesSubs.unsubscribe();
    this.cofradiasSubs.unsubscribe();
    this.bancosSubs.unsubscribe();
  }

  clear() {
    this.filters = {}
    this.vigilanteCtrl.setValue('')
    this.bancoCtrl.setValue('')
    this.embarcacionCtrl.setValue('')
    this.especieCtrl.setValue('')
    this.buscarCtrl.setValue('')
    this.range.controls.start.setValue(new Date())
    this.range.controls.end.setValue(new Date())
    let params;
    if (this.range.value.end) {
      params = {
        finicio: moment(this.range.value.start).format("YYYY-MM-DD"),
        final: moment(this.range.value.end).format("YYYY-MM-DD")
      }
      if (params.finicio != params.final) {
        this.displayedColumns = ["select", "id", "embarcacion", "dia", "entrada", "tripulacion", "banco", "babosa", "fina", "japonica", "berberecho", "birollo", "especies"];
      } else {
        this.displayedColumns = ["select", "id", "embarcacion", "entrada", "tripulacion", "banco", "babosa", "fina", "japonica", "berberecho", "birollo", "especies"];
      }
      this.getControles(params)
    }
    this.selection.clear()
  }


  mapControls(controls: any) {

    let mappedControls: any[] = [];
    controls.forEach((c: any) => {

      let n: any = {};
      n.bancos = c.bancos;
      n.cofradia = c.cofradia;
      n.coordenadas = c.coordendas;
      n.coordenadasUTM = c.coordenadasUTM;
      n.embarcacion = c.embarcacion;
      n.especies = c.especies;
      n.hora = c.hora;
      n.horaActividad = c.horaActividad;
      n.horaIni = c.horaIni;
      n.id = c.id;
      n.tripulantes = c.tripulantes;
      n.uid = c.uid;
      n.fecha = c.fecha;
      n.usuario = c.usuario;

      n.especies.forEach((e: any) => {
        if (n[e.nombre]) {
          n[e.nombre] += e.cantidad;
        } else {
          n[e.nombre] = e.cantidad;
        }
      });
      // Si no hay alguna especie, no se pone valor
      if (!n['BABOSA']) {
        n['BABOSA'] = '--'
      }
      if (!n['FINA']) {
        n['FINA'] = '--'
      }
      if (!n['JAPONICA']) {
        n['JAPONICA'] = '--'
      }
      if (!n['BERBERECHO']) {
        n['BERBERECHO'] = '--'
      }
      if (!n['BIROLLO']) {
        n['BIROLLO'] = '--'
      }
      mappedControls.push(n);
    });
    console.log('LISTA COMPONENTES mapControls() mapped controls .... ', mappedControls);
    return mappedControls;
  }

  showBancos(bancos) {
    let strBancos = "";
    let count = 1;
    if (Array.isArray(bancos)) {
      bancos.forEach((b) => {
        if (count != bancos.length)
          strBancos += b.nombre + ", ";
        else
          strBancos += b.nombre;
        count += 1
      });
    }
    return strBancos;
  }

  getTotalWeight(especie: string): any {
    let sum = 0
    this.controles.forEach(el => {
      if (el[especie]) {
        if (el[especie] != '--')
          sum += el[especie]
      }
    });
    sum = Math.round((sum + Number.EPSILON) * 100) / 100 // Necesario para que no salgan decimales raros en el total
    if (sum == 0)
      return "--"
    else
      return sum

  }
  getTotal() {
    let sum = 0
    this.controles.forEach(el => {
      if (el['tripulantes']) {
        sum += el['tripulantes']
      }
    });
    sum = Math.round((sum + Number.EPSILON) * 100) / 100 // Necesario para que no salgan decimales raros en el total
    if (sum == 0)
      return "--"
    else
      return sum
  }
}
