import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../material.module';
import { AdminBuquesComponent } from './admin-buques/admin-buques.component';
import { EditControlComponent } from './edit-control/edit-control.component';
import { ListaControlesComponent } from './lista-controles/lista-controles.component';
import { LoadingExcelDialogComponent } from './loading-excel-dialog/loading-excel-dialog.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { ModalEspecieComponent } from './modal-especie/modal-especie.component';
import { ModalMostrarEspeciesComponent } from './modal-mostrar-especies/modal-mostrar-especies.component';
import { ModalMessageComponent } from './modal-message/modal-message.component';
import { AdminEspeciesComponent } from './admin-especies/admin-especies.component';
import { AdminBancosComponent } from './admin-bancos/admin-bancos.component';
import { AdminUsuariosComponent } from './admin-usuarios/admin-usuarios.component';


@NgModule({
     imports: [
          CommonModule,
          MainRoutingModule,
          FormsModule,
          ReactiveFormsModule,
          MaterialModule,
     ],
     declarations: [
          MainComponent,
          ListaControlesComponent,
          EditControlComponent,
          ModalMostrarEspeciesComponent,
          ModalEspecieComponent,
          AdminBuquesComponent,
          LoadingExcelDialogComponent,
          ModalMessageComponent,
          AdminEspeciesComponent,
          AdminBancosComponent,
          AdminUsuariosComponent
     ],
})

export class MainModule { }