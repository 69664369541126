import {
  Injectable
} from '@angular/core';
import {
  HttpHeaders,
  HttpClient
} from '@angular/common/http';
import {
  ConfigService
} from './config.service';
import {
  Observable,
  of
} from 'rxjs';
import {
  catchError
} from 'rxjs/internal/operators/catchError';
import {
  map,
  tap
} from 'rxjs/operators';
import {
  Banco,
  Buque,
  Cofradia,
  Especie,
  Tipo,
  Usuario
} from '../models/maestros'
import {
  saveAs
} from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private BASE_URL: string;
  private HEADER_TYPE: string;
  private API_KEY: string;
  private HEADERS_GET: HttpHeaders;
  private HEADERS_POST: HttpHeaders;
  private GET_EMBARCACIONES_MAESTRAS: string;
  private GET_EMBARCACIONES: String;
  private POST_EMBARCACION: string;
  private DEL_EMBARCACION: string;
  private GET_COFRADIAS: String;
  private GET_BANCOS: String;
  private GET_ESPECIES: String;
  private GET_ROLES: String;
  private GET_TECNICOS: String;
  private GET_USUARIOS: String;
  private GET_VIGILANTES: String;
  private GET_CONTROLES: String;
  private GET_EXCEL: String;
  private POST_CONTROL: String;
  private DEL_CONTROL: String;
  POST_ESPECIE: String;
  DEL_ESPECIE: String;
  GET_TIPOS: any;
  POST_BANCO: any;
  DEL_BANCO: any;
  GET_BANCOS_MAESTROS: any;
  GET_USUARIOS_MAESTROS: any;
  POST_USUARIO: any;
  DEL_USUARIO: any;

  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
    this.BASE_URL = this.config.getURI();
    this.API_KEY = this.config.getENV_CONFIG('API_KEY');
    this.HEADER_TYPE = this.config.getENV_CONFIG('HEADER_TYPE');
    this.GET_EMBARCACIONES_MAESTRAS = this.config.getSERVICE_CALL('GET_EMBARCACIONES_MAESTRAS');
    this.GET_EMBARCACIONES = this.config.getSERVICE_CALL('GET_EMBARCACIONES');
    this.POST_EMBARCACION = this.config.getSERVICE_CALL('POST_EMBARCACION');
    this.DEL_EMBARCACION = this.config.getSERVICE_CALL('DEL_EMBARCACION')
    this.POST_ESPECIE = this.config.getSERVICE_CALL('POST_ESPECIE');
    this.DEL_ESPECIE = this.config.getSERVICE_CALL('DEL_ESPECIE')
    this.POST_BANCO = this.config.getSERVICE_CALL('POST_BANCO');
    this.DEL_BANCO = this.config.getSERVICE_CALL('DEL_BANCO')
    this.POST_USUARIO = this.config.getSERVICE_CALL('POST_USUARIO');
    this.DEL_USUARIO = this.config.getSERVICE_CALL('DEL_USUARIO')
    this.GET_USUARIOS_MAESTROS = this.config.getSERVICE_CALL('GET_USUARIOS_MAESTROS');
    this.GET_ROLES = this.config.getSERVICE_CALL('GET_ROLES');
    this.GET_COFRADIAS = this.config.getSERVICE_CALL('GET_COFRADIAS');
    this.GET_TIPOS = this.config.getSERVICE_CALL('GET_TIPOS');
    this.GET_BANCOS = this.config.getSERVICE_CALL('GET_BANCOS');
    this.GET_BANCOS_MAESTROS = this.config.getSERVICE_CALL('GET_BANCOS_MAESTROS');
    this.GET_ESPECIES = this.config.getSERVICE_CALL('GET_ESPECIES');
    this.GET_VIGILANTES = this.config.getSERVICE_CALL('GET_VIGILANTES');
    this.GET_CONTROLES = this.config.getSERVICE_CALL('GET_CONTROLES');
    // es un método post, las ids de un mes son demasiadas para url
    this.GET_EXCEL = this.config.getSERVICE_CALL('GET_EXCEL');
    this.POST_CONTROL = this.config.getSERVICE_CALL('POST_CONTROL')
    this.DEL_CONTROL = this.config.getSERVICE_CALL('DEL_CONTROL');
    this.HEADERS_GET = new HttpHeaders().set("Apikey", this.API_KEY);
    this.HEADERS_POST = new HttpHeaders().set("Apikey", this.API_KEY);
    // this.HEADERS_POST.append('header', 'Content-Type:application/json')

  }
  // MAESTROS
  getBancos() {
    let url = `${this.BASE_URL}${this.GET_BANCOS}`;
    return this.http.get < Array < Banco[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Bancos obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE getBancos() ...', []))
      ));
  }
  getBancosMaestros(estado) {
    let url = `${this.BASE_URL}${this.GET_BANCOS_MAESTROS}/${estado}`;
    console.log(url)
    return this.http.get < Array < Banco[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Bancos obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE getBancos() ...', []))
      ));
  }
  getEmbarcacionesMaestras(estado) {
    let url = `${this.BASE_URL}${this.GET_EMBARCACIONES_MAESTRAS}/${estado}`;
    console.log(url)
    return this.http.get < Array < Buque[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Embarcaciones obtenidas"),
        catchError(this.handleError < any > ('RED SERVICE getEmbarcaciones() ...', []))
      ));
  }
  getEmbarcaciones() {
    let url = `${this.BASE_URL}${this.GET_EMBARCACIONES}`;
    return this.http.get < Array < Buque[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Embarcaciones obtenidas"),
        catchError(this.handleError < any > ('RED SERVICE getEmbarcaciones() ...', []))
      ));
  }
  getUsuariosMaestros(estado) {
    let url = `${this.BASE_URL}${this.GET_USUARIOS_MAESTROS}/${estado}`;
    console.log(url)
    return this.http.get < Array < Usuario[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Usuarios obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE getUsuarios() ...', []))
      ));
  }
  saveEmbarcacion(embarcacion: any) {
    let url = `${this.BASE_URL}${this.POST_EMBARCACION}`;
    console.log("RED SERVICE saveEmbarcacion() url .... ", url);
    return this.http.post(url, embarcacion, {
      headers: this.HEADERS_POST
    }).pipe(
      tap(() => console.log("Embarcación guardada "),
        catchError(this.handleError < any > ('RED SERVICE saveEmbarcacion() ...', []))
      )
    );
  }

  deleteEmbarcacion(id: number) {
    let url = `${this.BASE_URL}${this.DEL_EMBARCACION}${id}`;
    console.log("RED SERVICE deleteEmbarcaccion() url .... ", url);
    return this.http.delete(url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Borrada embarcacion"),
        catchError(this.handleError < any > ('RED SERVICE deleteEmbarcacion() ...', []))
      ));
  }

  saveUsuario(usuario: any) {
    let url = `${this.BASE_URL}${this.POST_USUARIO}`;
    console.log("RED SERVICE saveUsuario() url .... ", url);
    return this.http.post(url, usuario, {
      headers: this.HEADERS_POST
    }).pipe(
      tap(() => console.log("Usuario guardado "),
        catchError(this.handleError < any > ('RED SERVICE saveUsuario() ...', []))
      )
    );
  }

  deleteUsuario(id: number) {
    let url = `${this.BASE_URL}${this.DEL_USUARIO}${id}`;
    console.log("RED SERVICE deleteUsuario() url .... ", url);
    return this.http.delete(url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Borrado usuario"),
        catchError(this.handleError < any > ('RED SERVICE deleteUsuario() ...', []))
      ));
  }

  getEspeciesMaestras(estado) {
    let url = `${this.BASE_URL}${this.GET_ESPECIES}/${estado}`;
    console.log(url)
    return this.http.get < Array < Especie[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Especies obtenidas"),
        catchError(this.handleError < any > ('RED SERVICE getEspecies() ...', []))
      ));
  }

  saveEspecie(especie: any) {
    let url = `${this.BASE_URL}${this.POST_ESPECIE}`;
    console.log("RED SERVICE saveEmbarcacion() url .... ", url);
    return this.http.post(url, especie, {
      headers: this.HEADERS_POST
    }).pipe(
      tap(() => console.log("Especie guardada "),
        catchError(this.handleError < any > ('RED SERVICE saveEspecie() ...', []))
      )
    );
  }

  deleteEspecie(id: number) {
    let url = `${this.BASE_URL}${this.DEL_ESPECIE}${id}`;
    console.log("RED SERVICE deleteEspecie() url .... ", url);
    return this.http.delete(url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Borrada especie"),
        catchError(this.handleError < any > ('RED SERVICE deleteEspecie() ...', []))
      ));
  }

  saveBanco(banco: any) {
    banco.coords = JSON.stringify(banco.coords)
    banco.puntos = JSON.stringify(banco.puntos)
    let url = `${this.BASE_URL}${this.POST_BANCO}`;
    console.log("RED SERVICE saveBanco() url .... ", url);
    return this.http.post(url, banco, {
      headers: this.HEADERS_POST
    }).pipe(
      tap(() => console.log("Banco guardado"),
        catchError(this.handleError < any > ('RED SERVICE saveBanco() ...', []))
      )
    );
  }

  deleteBanco(id: number) {
    let url = `${this.BASE_URL}${this.DEL_BANCO}${id}`;
    console.log("RED SERVICE deleteBanco() url .... ", url);
    return this.http.delete(url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Borrado banco"),
        catchError(this.handleError < any > ('RED SERVICE deleteBanco() ...', []))
      ));
  }

  getCofradias() {
    let url = `${this.BASE_URL}${this.GET_COFRADIAS}`;
    return this.http.get < Array < Cofradia[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Cofradías obtenidas"),
        catchError(this.handleError < any > ('RED SERVICE getCofradias() ...', []))
      ));
  }

  getTipos() {
    let url = `${this.BASE_URL}${this.GET_TIPOS}`;
    return this.http.get < Array < Tipo[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Tipos obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE getTipos() ...', []))
      ));
  }

  getEspecies(estado) {
    let url = `${this.BASE_URL}${this.GET_ESPECIES}/${estado}`;
    return this.http.get < Array < Especie[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Especies obtenidas"),
        catchError(this.handleError < any > ('RED SERVICE getEspecies() ...', []))
      ));
  }

  getRoles() {
    let url = `${this.BASE_URL}${this.GET_ROLES}`;
    return this.http.get < Array < any[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log(" obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE () ...', []))
      ));
  }

  getTecnicos() {
    let url = `${this.BASE_URL}${this.GET_TECNICOS}`;
    return this.http.get < Array < any[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log(" obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE () ...', []))
      ));
  }

  getUsuarios() {
    let url = `${this.BASE_URL}${this.GET_USUARIOS}`;
    return this.http.get < Array < any[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log(" Usuarios obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE () ...', []))
      ));
  }

  getVigilantes() {
    let url = `${this.BASE_URL}${this.GET_VIGILANTES}`;
    return this.http.get < Array < any[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Vigilantes obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE () ...', []))
      ));
  }

  // GESTION CONTROLES
  getControles(params: any) {
    let url = `${this.BASE_URL}${this.GET_CONTROLES}/${params.finicio}/${params.final}`;
    return this.http.get < Array < any[] >> (url, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Controles obtenidos"),
        catchError(this.handleError < any > ('RED SERVICE getControles() ...', []))
      ));
  }

  getExcelControles(params) {
    let url = `${this.BASE_URL}${this.GET_EXCEL}`;
    console.log("RED SERVICE getExcelControles() url .... ", url);
    return this.http.post < any > (url, params, {
      headers: this.HEADERS_POST
    }).pipe(
      tap(() => console.log("Excel obtenido"),
        catchError(this.handleError < any > ('RED SERVICE getExcelControles() ...', []))
      ));
  }

  downloadExcel(url) : Observable < any >{
    console.log("RED SERVICE downloadExcel() url .... ", url);
    return this.http.get(url, {
      headers: this.HEADERS_GET,
      'responseType': 'blob'
    }).pipe(
      map((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel'
        });
        let name = url.split('/')
        saveAs(response, name[name.length - 1]);
      }),
      catchError(this.handleError < any > ('RED SERVICE downloadExcel() ...', []))
    );
  }

  updateControl(params: any) {
    let url = `${this.BASE_URL}${this.POST_CONTROL}`;
    console.log("RED SERVICE updateControl() url .... ", params);
    return this.http.post(url, params, {
      headers: this.HEADERS_POST
    }).pipe(
      tap(() => console.log("Control guardado"),
        catchError(this.handleError < any > ('RED SERVICE getControles() ...', []))
      )
    );
  }
  /** 
   * Borra un control según el uid (borrado lógico)
   * @param json con usuario e id
   */
  deleteControl(params: any) {
    let url = `${this.BASE_URL}${this.DEL_CONTROL}`;
    console.log("RED SERVICE deleteControl() url .... ", url);
    return this.http.post(url, params, {
      headers: this.HEADERS_GET
    }).pipe(
      tap(() => console.log("Borrado control"),
        catchError(this.handleError < any > ('RED SERVICE deleteControl() ...', []))
      ));
  }

  // UTILS
  private handleError < T > (operation = 'operation', result ? : T) {
    return (error: any): Observable < T > => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
