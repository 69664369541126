import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ModalMessageComponent } from '../modal-message/modal-message.component';

@Component({
  selector: 'app-admin-bancos',
  templateUrl: './admin-bancos.component.html',
  styleUrls: ['./admin-bancos.component.css']
})
export class AdminBancosComponent implements OnInit {

  tituloFormulario = "Alta de banco"


  // datos maestros 
  bancosFiltradas: Observable<any>;
  bancos: any[];
  bancosMaestras: any[];
  bancoCtrl = new FormControl();
  bancosSubs: Subscription;


  // datos formulario
  idCtrl = new FormControl();
  formularioId: number;

  nombreCtrl = new FormControl();
  formularioNombre: string;

  codigoCtrl = new FormControl();
  formularioCodigo: string;

  observacionesCtrl = new FormControl();
  formularioObservaciones: string;

  estadoCtrl = new FormControl();
  formularioEstado: number;

  formularioEstados: any[] = [{ 'id': 1, 'nombre': 'Activo' }, { 'id': 2, 'nombre': 'Baja' }];

  dataSource;

  displayedColumns: string[] = ["nombre", "codigo", "editar", "borrar"];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  estado: any = 1;


  constructor(
    private api: ApiService, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // console.clear();
    console.log("INIT ADMIN BUQUES .... ");

    // INICIO DE BANCOES 
    this.getBancos();

    // INICIO DE FORMULARIO 
    this.idCtrl.disable();
  }

  // FUNCIONES DE FILTRO 
  filtrarBancos(value: string) {
    const filterValue = value.toLowerCase();
    return this.bancos.filter(banco => banco.nombre.toLowerCase().includes(filterValue));
  }


  cancelar() {
    this.formularioId = null;
    this.formularioNombre = null;
    this.formularioCodigo = null;
    this.formularioObservaciones = null;

    this.estadoCtrl.setValue(null);
    this.formularioEstado = null;
    this.tituloFormulario = "Alta de embarcación"
  }

  guardarBanco() {
    let banco = {};

    if (!this.formularioNombre) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establercer un nombre de la banco ",
        width: '500px',
      });
      // alert("Debe establercer un nombre del banco ")
      return;
    }

    if (!this.formularioCodigo) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establer un código",
        width: '500px',
      });
      // alert("Debe establer un matrícula");
      return;
    }

    banco = {
      id: this.formularioId ? this.formularioId : null,
      nombre: this.formularioNombre.trim(),
      codigo: this.formularioCodigo.trim(),
      estado: this.formularioEstado ? this.formularioEstado : 1,
      usuario: JSON.parse(localStorage.getItem('usuario'))

    }

    console.log("Banco que se va  enviar .... ", banco);

    this.api.saveBanco(banco).subscribe(
      (data: any) => {
        console.log("SAVE BANCO data .... ", data);
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getBancos();
          this.bancoCtrl.setValue('')
        }

      },
      error => {
        console.log("SAVE BANCO error .... ", error);
      }
    )
  }


  // OPERACIONES LISTA 
  // seleccionar banco
  selectBancoFromAutocomplete(banco) {

    this.bancos = this.bancos.filter(
      (e) => e.nombre.toUpperCase() === banco
    );
    this.dataSource = new MatTableDataSource<any>(this.bancos);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setFiltroEstado(estado) {
    this.estado = estado
    if (estado === 1 || estado === 2) {
      this.bancos = this.bancosMaestras.filter(
        e => e.estado === estado
      )
    }

    if (estado === 0) {
      this.bancos = this.bancosMaestras;
    }

    this.dataSource = new MatTableDataSource<any>(this.bancos);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  // limpiar filtro 
  resetearFiltro(event) {
    if (event.target.value.length <= 3) {
      this.bancos = this.bancosMaestras;
      this.dataSource = new MatTableDataSource<any>(this.bancos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  onEnter(evt: any) {
    if (evt.source.selected) {
    }
  }

  // operaciones
  editarBanco(banco) {
    this.tituloFormulario = "Modificación de embarcación"

    console.log("Modificación de embarcación  .... ", banco);

    this.formularioId = banco.id;
    this.formularioNombre = banco.nombre;
    this.formularioCodigo = banco.codigo;
    this.formularioEstado = banco.estado
  }

  borrarBanco(data) {
    this.api.deleteBanco(data.id).subscribe(
      (data: any) => {
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getBancos();
          this.bancoCtrl.setValue('')
        }
      },
      (error) => {
        console.log("BORRAR BANCO error .... ", error);

      }
    )
  }

  activarBanco(data) {
    data.estado = 1
    this.api.saveBanco(data).subscribe(
      (data: any) => {
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getBancos();
          this.bancoCtrl.setValue('')
        }
      },
      (error) => {
        console.log("ACTIVAR BANCO error .... ", error);

      }
    )
  }

  getBancos(params = 0) {
    this.bancosSubs = this.api.getBancosMaestros(params).subscribe(
      (data: any) => {
        if (data.exito) {
          this.bancos = data.bancos;
          this.bancosMaestras = data.bancos;
          console.log('bancos ', this.bancos);

          this.dataSource = new MatTableDataSource<any>(this.bancos);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.bancosFiltradas = this.bancoCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarBancos(value))
            )
          console.log('bancos filtradas', this.bancosFiltradas);
          this.setFiltroEstado(this.estado)
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getBancos error .... ", error
        );
      }
    );

  }

  ngOnDestroy() {
    this.bancosSubs.unsubscribe();
  }

}
