import { ConfigService } from './../services/config.service';
import { AuthService } from './../services/auth.service';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import * as moment from 'moment';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from '../main/modal-message/modal-message.component';


@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  API_KEY: string = null;
  isRefreshing: any = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private config: ConfigService,
    private inj: Injector
  ) {
    this.API_KEY = this.config.getAPI_KEY();
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.get("skip")) {
      const reqSimple = req.clone({ headers: req.headers.delete('skip') })
      return next.handle(reqSimple)
    }
    const settings = JSON.parse(localStorage.getItem("settings"));
    
    let request = req;
    if (settings) {
      request = this.addToken(request, settings.token);
      // let expires = new Date(moment(settings.expires).format("YYYY MM DD HH:mm:ss"));
      // // let limit = new Date(moment(settings.expires).subtract(2, 'minutes').format("YYYY MM DD HH:mm:ss"));
      // let now = new Date(moment().format("YYYY MM DD HH:mm:ss"));

      // if (expires < now) {
      //   console.log("JWT INTERCEPTOR intercept() token expirado ....  ");
      //   // alert("El token ha expirado.");
      //   expires = new Date(moment(settings.expiresRefresh).format("YYYY MM DD HH:mm:ss"));
      //   if (expires < now) {
      //     alert("El token ha expirado.");
      //     this.auth.logOut();
      //   } else {
      //     this.auth.getToken().subscribe(
      //       data => this.auth.setSettings(data),
      //       error => console.log("JWT INTERCEPTOR intercept() token a punto de expirar ....  error", error)
      //     );
      //   }
        
        
      // }
      // if (expires > now && now > limit) {
      //   console.log("JWT INTERCEPTOR intercept() token a punto de expirar .... renovar token");
      //   this.auth.getToken().subscribe(
      //     data => this.auth.setSettings(data),
      //     error => console.log("JWT INTERCEPTOR intercept() token a punto de expirar ....  error", error)
      //   );
      // }

      // const token = settings.token;
      // const authtRequest = req.clone({ headers: req.headers.set('Authorization', `${token}`) });
      // return next.handle(authtRequest);
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (!request.url.includes("token") && err.status === 401) {
          return this.handle401Error(request, next);
        } else if (request.url.includes("token")) {
          const authService = this.inj.get(AuthService);
          authService.logOut()
          const modal = this.inj.get(MatDialog);
          const dialogRef = modal.open(ModalMessageComponent, {
            data: "Ha expirado la sesión",
            width: '500px',
          });
        }

        return throwError( err );
      })
    );
    // return next.handle(req.clone());
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const authService = this.inj.get(AuthService);
  
      return authService.getToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          localStorage.setItem("settings", JSON.stringify(token));
          return next.handle(this.addToken(request, token.token));
        }));
  
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
