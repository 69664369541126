<div class="wrapper">
     <mat-dialog-content class="mat-typography">
          <mat-card>
               <mat-card-header>
                    <mat-card-title>
                         {{titulo}}
                    </mat-card-title>
               </mat-card-header>
               <mat-card-content>
                    <form class="formulario">
                         <!-- especie   -->
                         <mat-form-field appearance="outline">
                              <mat-label>Especie</mat-label>
                              <input type="text" matInput #inputEspecie [matAutocomplete]="especie"
                                   [formControl]="especieCtrl" placeholder="especie ... ">
                              <mat-icon matSuffix fontSet="fas" fontIcon="fa-tag" class="formulario"></mat-icon>
                              <mat-autocomplete autoActiveFirstOption #especie="matAutocomplete">
                                   <mat-option *ngFor="let especie of especiesFiltradas | async"
                                        [value]="especie.nombre"
                                        (onSelectionChange)="inputEspecie.value != undefined && onEnter($event); selectEspecie(especie)">
                                        <span>{{especie.nombre }}</span>
                                   </mat-option>
                              </mat-autocomplete>
                         </mat-form-field>

                         <!-- banco   -->
                         <mat-form-field appearance="outline">
                              <mat-label>Banco</mat-label>
                              <input type="text" matInput #inputBanco [matAutocomplete]="banco"
                                   [formControl]="bancoCtrl" placeholder="banco ... ">
                              <mat-icon matSuffix fontSet="fas" fontIcon="fa-map-marker-alt" class="formulario">
                              </mat-icon>
                              <mat-autocomplete autoActiveFirstOption #banco="matAutocomplete">
                                   <mat-option *ngFor="let banco of bancosFiltrados | async" [value]="banco.nombre"
                                        (onSelectionChange)="inputBanco.value != undefined && onEnter($event); selectBanco(banco)">
                                        <span>{{banco.nombre}}</span>
                                   </mat-option>
                              </mat-autocomplete>
                         </mat-form-field>
                         <!-- cantidad   -->
                         <mat-form-field appearance="outline">
                              <mat-label>Cantidad</mat-label>
                              <input type="number" #cantidad matInput [formControl]="cantidadCtrl"
                                   placeholder="cantidad ... " (keyup)="setCantidad(cantidad.value)">
                              <mat-icon matSuffix>mode_edit</mat-icon>
                         </mat-form-field>
                    </form>

               </mat-card-content>
               <mat-dialog-actions align="end">
                    <button mat-button mat-raised-button class="button-red" (click)="cancelar()">
                         <mat-icon>clear</mat-icon> cancelar
                    </button>
                    <button mat-button mat-raised-button class="button-blue" [mat-dialog-close]="true" cdkFocusInitial
                         (click)="guardarEspecie()">
                         <!-- <mat-icon matPrefix fontSet="fas" fontIcon="fa-check" class="modal-especies__button-mat-icon">
                    </mat-icon> -->
                         <mat-icon>done</mat-icon> Aceptar
                    </button>
               </mat-dialog-actions>
          </mat-card>
     </mat-dialog-content>
</div>