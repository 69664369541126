<div class="container">
     <header>
          <mat-icon fontSet="far" fontIcon="fa-compass"></mat-icon> Asociación de Marisqueo a Flote Ria de Vigo
     </header>
     <main>
          <div class="formulario">
               <header>
                    Opciones de navegación
               </header>
               <div class="botonera">
                    <div class="opcion" title="Ir a la web de gestión de muestreos" (click)=irAMuestreos()>
                         <div class="icon">
                              <img src="./../../assets/icons/icon-caliber-512-white.png" alt="calibre">
                         </div>
                         <div>Ir a Muestreos</div>
                    </div>
                    <div class="opcion" title="Ir a la web de gestión controles de marisqueo" (click)="irAControles()">
                         <div class="icon">
                              <mat-icon fontSet="far" fontIcon="fa-compass"></mat-icon>
                         </div>
                         <div>Ir a Controles</div>
                    </div>
               </div>
          </div>
     </main>
     <footer>
          <img class="img-footer" src="assets/logos/union-europea-femp.png" alt="ue">
          <img class="img-footer" src="assets/logos/xunta-galicia.png" alt="xunta">
          <img class="img-footer" src="assets/logos/footer-marca-galicia-branco.png" alt="galicia">
     </footer>
</div>