/* FICHERO COFIG APP WEB GESTION MARISQUEO  */

export const ENV_CONFIG = {
    PROTOCOL: 'https',
    //PROTOCOL: 'http', // local
    DOMAIN_NAME_SAMPLINGS_SERVICES: 'api.cofradiarianxo.com', // PRODUCCIÓN
    // DOMAIN_NAME_SAMPLINGS_SERVICES: 'rianxo-api.saecdata.net', // pre
    // DOMAIN_NAME_SAMPLINGS_SERVICES: '195.114.216.208:12000', 
    // DOMAIN_NAME_SAMPLINGS_SERVICES: 'localhost:12000', // local
    PORT: '',
    HEADER_TYPE: 'application/x-form-ulencoded',
    CLIENT_NAME: 'web-gestion',
    API_KEY: 'c3362c4cdc92644eb0261d8e8f6bbad3b66e27fb',
    TOKEN_RENEW: 270000, // em ms, 4:30 min.
    REDIRECT_DOMAIN_NAME: "riavigo-muestreos.saecdata.net",  // pro
    // REDIRECT_DOMAIN_NAME: "localhost:8100", // local
    REDIRECT_SECTION_NAME: "login",
    VERSION: '1.0.0'
}

export const SAMPLING_SERVICE_CONFIG = {
    BASE_URL: `${ENV_CONFIG.PROTOCOL}://${ENV_CONFIG.DOMAIN_NAME_SAMPLINGS_SERVICES}${(ENV_CONFIG.PORT !== '') ? ':' + ENV_CONFIG.PORT : ''}`,
    GET_TOKEN: '/token',
    DECODE_TOKEN: '/decode',
    POST_LOGIN: '/login',
    GET_EMBARCACIONES_MAESTRAS: '/buques',
    GET_EMBARCACIONES: '/buques-simplificados',
    GET_COFRADIAS: '/cofradias',
    GET_BANCOS: '/bancos-simplificados',
    GET_BANCOS_MAESTROS: '/bancos',
    GET_ESPECIES: '/especies-controles',
    GET_CONTROLES: '/controles',
    GET_EXCEL: '/excel-controles/',
    GET_ROLES: '/roles',
    GET_TECNICOS: '/tecnicos',
    GET_USUARIOS: '/usuarios',
    GET_VIGILANTES: '/vigilantes',
    POST_CONTROL: '/control',
    DEL_CONTROL: '/borrar-control/',
    POST_EMBARCACION: '/buque',
    DEL_EMBARCACION: '/buque/',
    POST_ESPECIE: '/especie',
    DEL_ESPECIE: '/especie/',
    GET_TIPOS: '/tipos',
    POST_BANCO: '/banco',
    DEL_BANCO: '/banco/',
    POST_USUARIO: '/usuario',
    DEL_USUARIO: '/usuario/',
    GET_USUARIOS_MAESTROS: '/usuarios',

}

export const LOGS = {
    APP: true,

}