<div class="container">
    <header>
        {{title}}
        <!-- <img src="assets/logos/logo_blanco_sinletra.png" alt="logo" width="25vw" height="35vh">&nbsp;&nbsp; -->
    </header>
    <main>
        <img src="assets/logos/logo-solo.png" alt="logo2" width="5%" style="padding: 5vh;">
        <div class="formulario">
            <header class="title-header">
                Registro de usuario
            </header>
            <form class="formulario-login" action="">
                <mat-form-field class="campo-formulario-login">
                    <mat-label>Usuario</mat-label> <span matPrefix>
                        <mat-icon>person</mat-icon> &nbsp;
                    </span>

                    <input type="text" #user name="usuario" matInput placeholder="Nombre de usuario"
                        (keydown.enter)="setFocus('pass')" [(ngModel)]="username">
                </mat-form-field>
                <mat-form-field class="campo-formulario-login">
                    <mat-label>Contraseña</mat-label> <span matPrefix>
                        <mat-icon>lock</mat-icon> &nbsp;
                    </span>
                    <input [type]="mostrarPassword ? 'text' : 'password'" #pass name="pass" matInput
                        placeholder="Contraseña de usuario" [(ngModel)]="password" (keydown.enter)="login()">
                    <mat-icon matSuffix *ngIf="!mostrarPassword" (click)="toggleType()">visibility</mat-icon>
                    <mat-icon matSuffix *ngIf="mostrarPassword" (click)="toggleType()">visibility_off</mat-icon>
                </mat-form-field>
                <button class="boton-enviar-formulario-login" #entrar mat-raised-button mat-button color="primary"
                    [disabled]="habilitarBotonEntrar()" (click)="login()">Entrar</button>
            </form>
        </div>
    </main>
    <footer>
        <img class="img-footer" src="assets/logos/logo-galp-arousa.png" alt="galp">
        <img class="img-footer" src="assets/logos/logo-ue-femp.png" alt="ue">
        <img class="img-footer" src="assets/logos/logo-xunta-conselleria.png" alt="galicia">

    </footer>
</div>