import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ModalMessageComponent } from '../modal-message/modal-message.component';

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.css']
})
export class AdminUsuariosComponent implements OnInit {

  tituloFormulario = "Alta de usuario"


  // datos maestros 
  usuariosFiltradas: Observable<any>;
  usuarios: any[];
  usuariosMaestras: any[];
  usuarioCtrl = new FormControl();
  usuariosSubs: Subscription;


  // datos formulario
  idCtrl = new FormControl();
  formularioId: number;

  nombreCtrl = new FormControl();
  formularioNombre: string;

  nCompletoCtrl = new FormControl();
  formularioNCompleto: string;

  observacionesCtrl = new FormControl();
  formularioObservaciones: string;

  estadoCtrl = new FormControl();
  formularioEstado: number;

  rolCtrl = new FormControl();
  formularioRol: number;

  emailCtrl = new FormControl();
  formularioEmail: string;

  passCtrl = new FormControl();
  formularioPass: string;

  edit = false

  formularioEstados: any[] = [{ 'id': 1, 'nombre': 'Activo' }, { 'id': 2, 'nombre': 'Baja' }];

  dataSource;

  displayedColumns: string[] = ["nombre", "nombreCompleto", "editar", "borrar"];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  tiposSubs: Subscription;
  roles: any;
  estado: any = 1;
  


  constructor(
    private api: ApiService, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // console.clear();
    console.log("INIT ADMIN BUQUES .... ");

    // INICIO DE USUARIOES 
    this.getUsuarios();

    // SETEO DE TIPOS
    this.tiposSubs = this.api.getRoles().subscribe(
      (data: any) => {
        if (data.exito) {
          this.roles = data.roles;
          console.log('roles', this.roles);
          // this.tiposFiltrados = this.tipoCtrl.valueChanges
          //   .pipe(
          //     startWith(''),
          //     map((value) => this.filtrarTipos(value))
          //   );
          // console.log('tipos filtrados', this.tiposFiltrados);
        }
      },
      (error) => {
        console.log(
          "LISTA CONTROLES ngOnInit() getRoles error .... ", error);
      }
    );

    // INICIO DE FORMULARIO 
    this.idCtrl.disable();
  }

  // FUNCIONES DE FILTRO 
  filtrarUsuarios(value: string) {
    const filterValue = value.toLowerCase();
    return this.usuarios.filter(usuario => usuario.nombre.toLowerCase().includes(filterValue));
  }

  selectRoles(rol: any) {
    console.log(rol);

    this.formularioRol = rol.id;
  }


  cancelar() {
    this.edit = false
    this.formularioId = null;
    this.formularioNombre = null;
    this.formularioNCompleto = null;
    this.formularioObservaciones = null;
    this.formularioPass = null
    this.formularioEmail = null
    this.estadoCtrl.setValue(null);
    this.formularioEstado = null;
    this.rolCtrl.setValue(null);
    this.formularioRol = null;
    this.tituloFormulario = "Alta de embarcación"
  }

  guardarUsuario() {
    let usuario = {};

    if (!this.formularioNombre) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establercer un nombre de usuario ",
        width: '500px',
      });
      // alert("Debe establercer un nombre del usuario ")
      return;
    }

    if (!this.formularioNCompleto) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establer un nombre completo",
        width: '500px',
      });
      // alert("Debe establer un matrícula");
      return;
    }

    usuario = {
      id: this.formularioId ? this.formularioId : null,
      nombre: this.formularioNombre.trim(),
      nombreCompleto: this.formularioNCompleto.trim(),
      email: this.formularioEmail.trim(),
      rol: this.formularioRol,
      estado: this.formularioEstado ? this.formularioEstado : 1,
      usuario: JSON.parse(localStorage.getItem('usuario'))

    }

    if (!this.edit) {
      usuario['password'] = this.formularioPass
    } 

    console.log("Usuario que se va  enviar .... ", usuario);

    this.api.saveUsuario(usuario).subscribe(
      (data: any) => {
        console.log("SAVE USUARIO data .... ", data);
        if (data) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getUsuarios();
          this.usuarioCtrl.setValue('')
        }

      },
      error => {
        console.log("SAVE USUARIO error .... ", error);
      }
    )
  }


  // OPERACIONES LISTA 
  // seleccionar usuario
  selectUsuarioFromAutocomplete(usuario) {

    this.usuarios = this.usuarios.filter(
      (e) => e.nombre.toUpperCase() === usuario
    );
    this.dataSource = new MatTableDataSource<any>(this.usuarios);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setFiltroEstado(estado) {
    this.estado = estado
    if (estado === 1 || estado === 2) {
      this.usuarios = this.usuariosMaestras.filter(
        e => e.estado === estado
      )
    }

    if (estado === 0) {
      this.usuarios = this.usuariosMaestras;
    }

    this.dataSource = new MatTableDataSource<any>(this.usuarios);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  // limpiar filtro 
  resetearFiltro(event) {
    if (event.target.value.length <= 3) {
      this.usuarios = this.usuariosMaestras;
      this.dataSource = new MatTableDataSource<any>(this.usuarios);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  onEnter(evt: any) {
    if (evt.source.selected) {
    }
  }

  // operaciones
  editarUsuario(usuario) {
    this.tituloFormulario = "Modificación de embarcación"

    console.log("Modificación de embarcación  .... ", usuario);

    this.formularioId = usuario.id;
    this.formularioNombre = usuario.nombre;
    this.formularioNCompleto = usuario.nombreCompleto;
    this.formularioEstado = usuario.estado
    this.formularioEmail = usuario.email
    this.formularioRol = usuario.rol.id
    this.rolCtrl.setValue(usuario.rol.id)
    this.edit = true
  }

  borrarUsuario(data) {
    this.api.deleteUsuario(data.id).subscribe(
      (data: any) => {
        if (data) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getUsuarios();
          this.usuarioCtrl.setValue('')
        }
      },
      (error) => {
        console.log("BORRAR USUARIO error .... ", error);

      }
    )
  }

  activarUsuario(data) {
    data.estado = 1
    data.rol = data.rol.id
    this.api.saveUsuario(data).subscribe(
      (data: any) => {
        if (data) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getUsuarios();
          this.usuarioCtrl.setValue('')
        }
      },
      (error) => {
        console.log("ACTIVAR USUARIO error .... ", error);

      }
    )
  }

  getUsuarios(params = 0) {
    this.usuariosSubs = this.api.getUsuariosMaestros(params).subscribe(
      (data: any) => {
        if (data) {
          this.usuarios = data;
          this.usuariosMaestras = data;
          console.log('usuarios ', this.usuarios);

          this.dataSource = new MatTableDataSource<any>(this.usuarios);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.usuariosFiltradas = this.usuarioCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarUsuarios(value))
            )
          console.log('usuarios filtradas', this.usuariosFiltradas);
          this.setFiltroEstado(this.estado)
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getUsuarios error .... ", error
        );
      }
    );

  }

  ngOnDestroy() {
    this.usuariosSubs.unsubscribe();
  }
}
