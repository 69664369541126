<mat-dialog-content class="mat-typography">
     <mat-card>
          <mat-card-header>
               <mat-card-title>
                    Especies asociadas al buque {{data.embarcacion.nombre}} ({{data.embarcacion.matricula}}),
                    {{fecha}}
               </mat-card-title>
          </mat-card-header>

          <mat-card-content>
               <div *ngIf="!data.especies">
                    Control sin especies.
               </div>

               <div *ngIf="data.especies" class="mat-elevation-z8">
                    <table mat-table [dataSource]="especies">
                         <ng-container matColumnDef="nombre">
                              <th mat-header-cell *matHeaderCellDef>
                                   <mat-icon>local_offer</mat-icon> ESPECIE
                              </th>
                              <td mat-cell *matCellDef="let especie"> {{especie.nombre}} </td>
                         </ng-container>
                         <ng-container matColumnDef="fao">
                              <th mat-header-cell *matHeaderCellDef class="text-center">
                                   <mat-icon>style</mat-icon>
                                   FAO
                              </th>
                              <td mat-cell *matCellDef="let especie" class="text-center"> {{especie.fao}} </td>
                         </ng-container>
                         <ng-container matColumnDef="cantidad">
                              <th mat-header-cell *matHeaderCellDef class="text-right">
                                   <mat-icon>widgets</mat-icon> KG
                              </th>
                              <td mat-cell *matCellDef="let especie" class="text-right"> {{especie.cantidad}} </td>
                         </ng-container>
                         <ng-container matColumnDef="banco">
                              <th mat-header-cell *matHeaderCellDef class="text-right">
                                   <mat-icon>place</mat-icon> BANCO
                              </th>
                              <td mat-cell *matCellDef="let especie" class="text-right"> {{especie.banco.nombre}} </td>
                         </ng-container>
                         <tr mat-header-row *matHeaderRowDef="columnas"></tr>
                         <tr mat-row *matRowDef="let row; columns: columnas;"></tr>
                    </table>
               </div>
          </mat-card-content>
     </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
     <button mat-button mat-raised-button class="button-blue" [mat-dialog-close]="true" cdkFocusInitial>
          <mat-icon matPrefix fontSet="fas" fontIcon="fa-check" class="modal-especies__button-mat-icon"></mat-icon>
          Aceptar
     </button>
</mat-dialog-actions>