import { ConfigService } from './../services/config.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bypass',
  templateUrl: './bypass.component.html',
  styleUrls: ['./bypass.component.css']
})
export class BypassComponent implements OnInit {
  PROTOCOL: string;
  URL_BASE: string;
  SECTION: string;

  constructor(
    private router: Router,
    private config: ConfigService
  ) { }

  ngOnInit(): void {
    this.PROTOCOL = this.config.getENV_CONFIG('PROTOCOL');
    this.URL_BASE = this.config.getENV_CONFIG('REDIRECT_DOMAIN_NAME')
    this.SECTION = this.config.getENV_CONFIG('REDIRECT_SECTION_NAME')
  }

  irAMuestreos() {
    let aux = JSON.parse(localStorage.getItem("usuario"));
    let param = btoa(aux.settings.token);
    console.log('token b64', param);

    let myUrl = `${this.PROTOCOL}://${this.URL_BASE}/${this.SECTION}/${param}`;
    // myUrl = `${this.PROTOCOL}://${this.URL_BASE}`;

    console.log('URL .... ', myUrl);
    window.location.href = myUrl;
  }

  irAControles() {
    this.router.navigate(['main'])
  }
}
