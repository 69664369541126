<div class="wrapper">
    <!-- formulario -->
    <div class="formulario">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    {{tituloFormulario}}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <div class="formulario-row-id">
                        <mat-form-field class="input-id mat-input-disabled-custom">
                            <input #inputId matInput disabled [formControl]="idCtrl" [(ngModel)]="formularioId">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-nombre">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input #inputNombre matInput [formControl]="nombreCtrl" [(ngModel)]="formularioNombre"
                                placeholder="Nombre de la especie">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-datos-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Nombre Reducido</mat-label>
                            <input #inputNReducido matInput [formControl]="nReducidoCtrl"
                                [(ngModel)]="formularioNReducido" placeholder="Nombre reducido de la especie">
                        </mat-form-field>

                        <!-- <mat-form-field class="half-width" appearance="outline">
                            <mat-label>Cupo</mat-label>
                            <input #inputCupo matInput disabled [formControl]="cupoCtrl"
                                [(ngModel)]="formularioCupo" placeholder="Cupo">
                        </mat-form-field>-->
                    </div>

                    <div class="formulario-row-datos-2">
                        <mat-form-field class="half-width" appearance="outline">
                            <mat-label>Nombre científico</mat-label>
                            <input #inputNCien matInput [formControl]="nCienCtrl" [(ngModel)]="formularioNCien"
                                placeholder="Nombre científico">
                        </mat-form-field>

                        <mat-form-field class="half-width" appearance="outline">
                            <mat-label>FAO</mat-label>
                            <input #inputFao matInput [formControl]="faoCtrl"
                                [(ngModel)]="formularioFao" placeholder="FAO">
                        </mat-form-field>
                    </div>

                    <div class="formulario-row-entidades">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select #selectTipo [formControl]="tipoCtrl">
                                <mat-option *ngFor="let tipo of tipos" [value]="tipo.id" (onSelectionChange)="selectTipos(tipo.id)"
                                    >{{tipo.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="formulario-row-observaciones">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Observaciones</mat-label>
                            <textarea #observaciones matInput [formControl]="observacionesCtrl"
                                [(ngModel)]="formularioObservaciones" placeholder="Observaciones"> </textarea>
                        </mat-form-field>
                    </div>

                    <!-- <div class="formulario-row-entidades">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Entidades</mat-label>
                            <mat-select #selectEntidades [formControl]="entidadesCtrl">
                                <mat-option *ngFor="let entidad of cofradias" [value]="entidad.id"
                                    (onSelectionChange)="selectEntidad(entidad)">{{entidad.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div> -->

                    <div class="formulario-row-estado">
                        <label id="radio-group-label-estado"> Estado de la especie</label>
                        <mat-radio-group #radioGroupEstado aria-labelledby="example-radio-group-label"
                            class="radio-group-estado" [formControl]="estadoCtrl" [(ngModel)]="formularioEstado">
                            <mat-radio-button class="radio-button-estado-option"
                                *ngFor="let estado of formularioEstados" [value]="estado.id">
                                {{estado.nombre}}
                            </mat-radio-button>

                        </mat-radio-group>

                    </div>

                    <div class="formulario-row-botonera">
                        <button mat-button mat-raised-button class="button-red" (click)="cancelar()">
                            <mat-icon>clear</mat-icon> Limpiar
                        </button>
                        <button mat-button mat-raised-button class="button-blue" (click)="guardarEspecie()">
                            <mat-icon>done</mat-icon> Guardar
                        </button>
                    </div>


                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- lista -->
    <div class="lista-especies">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Lista de especies
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="filtros-lista-especies">
                    <!-- AUTOCOMPLETE DE EMBARCARCIÓN -->
                    <mat-form-field class="no-padding-bottom filtro-especie" appearance="outline" (click)="inputEspecie.select()">
                        <mat-label>Especie</mat-label>
                        <input type="text" matInput #inputEspecie [matAutocomplete]="especie"
                            [formControl]="especieCtrl" (blur)="resetearFiltro($event)" />
                        <mat-autocomplete autoActiveFirstOption #especie="matAutocomplete" (optionSelected)='selectEspecieFromAutocomplete($event.option.value)'>
                            <mat-option *ngFor="let especie of especiesFiltradas | async"
                                [value]="especie.nombre.toUpperCase()">
                                <span>{{ especie.nombre | uppercase}} ({{ especie.fao | uppercase}})</span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix fontSet="fas" fontIcon="fa-ship" class="formulario"></mat-icon>
                    </mat-form-field>

                    <!-- TOGGLE DE ESTADO -->
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value=1 #gro="matButtonToggleGroup">
                        <mat-button-toggle value=1 (click)="setFiltroEstado(1)"> Activos</mat-button-toggle>
                        <mat-button-toggle value=2 (click)="setFiltroEstado(2)">Baja</mat-button-toggle>
                        <mat-button-toggle value=0 (click)="setFiltroEstado(0)">Todos</mat-button-toggle>
                    </mat-button-toggle-group>

                </div>
                <div class="lista-especies-2">
                    <table mat-table [dataSource]="dataSource" matSort class="lista-especies-tabla">

                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                NOMBRE
                            </th>
                            <td mat-cell *matCellDef="let especie">
                                {{ especie.nombre }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fao">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                FAO
                            </th>
                            <td mat-cell *matCellDef="let especie">
                                {{ especie.fao }}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="cupo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                CUPO
                            </th>
                            <td mat-cell *matCellDef="let especie">
                                {{ especie.cupo }}
                            </td>
                        </ng-container> -->

                        <ng-container matColumnDef="cientifico">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                CIENTÍFICO
                            </th>
                            <td mat-cell *matCellDef="let especie">
                                {{ especie.cientifico }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="editar">
                            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                            <td mat-cell *matCellDef="let row" (click)="editarEspecie(row)"
                                class="td-icon td-icon-blue">
                                <button mat-raised-button class="button-blue" title="Editar especie">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="borrar">
                            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                            <td mat-cell *matCellDef="let row" title="Dar de baja">
                                <button *ngIf="row.estado === 1" mat-raised-button class="button-red" (click)="borrarEspecie(row)">
                                    <mat-icon>delete_forever</mat-icon>
                                </button>
                                <button *ngIf="row.estado === 2" mat-raised-button class="button-green" (click)="activarEspecie(row)">
                                    <mat-icon>check_circle_outline</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->

                        <tr mat-row [ngClass]="row.estado === 2 ? 'baja ' : ''"
                            *matRowDef="let row; columns: displayedColumns"></tr>

                    </table>


                    <mat-paginator #paginator [pageSizeOptions]="[12, 25, 100]" [showFirstLastButtons]="true">
                    </mat-paginator>

                </div>
            </mat-card-content>
        </mat-card>



    </div>
</div>