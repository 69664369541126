import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(this.auth.getUsuarioLogueado())
    if (this.auth.getUsuarioLogueado()) {
      return true;
    }

    this.router.navigate(['login']);
    return false;

  }
}