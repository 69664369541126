import {
  Injectable,
  Injector
} from '@angular/core';
import {
  ConfigService
} from './config.service';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Observable,
  of
} from 'rxjs';
import {
  tap,
  catchError,
  map,
  first
} from 'rxjs/operators'
import {
  Router
} from '@angular/router';
import {
  ClassGetter,
  localizedString
} from '@angular/compiler/src/output/output_ast';
import {
  BnNgIdleService
} from 'bn-ng-idle';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  ModalMessageComponent
} from '../main/modal-message/modal-message.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  usuarioLogueado: any;
  settings: any;

  private BASE_URL: string;
  private HEADER_TYPE: string;
  private CLIENT_NAME: string;
  private API_KEY: string;
  private GET_TOKEN: String;
  private LOGIN: String;
  private HEADERS_GET: HttpHeaders;
  private HEADERS_POST: HttpHeaders;
  private DECODE_TOKEN: string;
  idleSub: any;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private router: Router,
    private bnIdle: BnNgIdleService, private inj: Injector, public dialog: MatDialog,

  ) {
    this.BASE_URL = this.config.getURI();
    this.CLIENT_NAME = this.config.getENV_CONFIG('CLIENT_NAME');
    this.API_KEY = this.config.getENV_CONFIG('API_KEY');
    this.HEADER_TYPE = this.config.getENV_CONFIG('HEADER_TYPE');
    this.GET_TOKEN = this.config.getSERVICE_CALL('GET_TOKEN');
    this.DECODE_TOKEN = this.config.getSERVICE_CALL('DECODE_TOKEN');
    this.LOGIN = this.config.getSERVICE_CALL('POST_LOGIN');
    this.HEADERS_GET = new HttpHeaders().set("Apikey", this.API_KEY);
    this.HEADERS_POST = new HttpHeaders({
      'Apikey': this.API_KEY
    });
  }

  // SETTERS & GETTERS 
  setUsuarioLogueado(usuario) {
    if (usuario === null) {
      localStorage.clear();
      return;
    }

    this.usuarioLogueado = usuario;
    localStorage.setItem("usuario", JSON.stringify(usuario))
    // this.setSettings(usuario.settings)
  }
  getUsuarioLogueado() {
    return this.usuarioLogueado;
  }
  setSettings(settings) {
    this.settings = settings;
    localStorage.setItem("settings", JSON.stringify(settings));
  }
  getSettings() {
    return JSON.parse(localStorage.getItem("settings"));
  }

  getUserByToken(): Observable < any > {
    const auth = this.getSettings();
    if (!auth) {
      return of(undefined);
    }
    let url = this.BASE_URL + this.DECODE_TOKEN;
    return this.http.post < any > (url, {
      token: auth.token
    }, {
      headers: {
        skip: "true"
      }
    }).pipe(
      map(data => {
        console.log(data)
        this.idleSub = this.bnIdle.startWatching(30 * 60).pipe(first()).subscribe((isTimedOut: boolean) => {
          if (isTimedOut) {
            if (this.getUsuarioLogueado()) {
              this.logOut()
              const modalService = this.inj.get(MatDialog);
              const dialogRef = this.dialog.open(ModalMessageComponent, {
                data: "Su sesión ha expirado",
                width: "500px",
              });

            }
          }
        });
        this.setUsuarioLogueado(data['usuario'])
      }),
      catchError(this.handleError < any > ('AUTH SERVICE decode() ....', []))
    )
  }

  // OPS
  logOut() {
    this.setUsuarioLogueado(null);
    this.setSettings(null);
    this.router.navigate(['login']);

  }
  // HTTP
  checkUser(usuario: string, password: string): Observable < any > {
    let passwordB64 = btoa(password);
    let params = {
      usuario: usuario,
      dato: passwordB64,
      apikey: this.API_KEY,
      appclient: this.CLIENT_NAME
    }
    let url = this.BASE_URL + this.LOGIN;
    return this.http.post < any > (url, params, {
      headers: {
        skip: "true",
      }
    }).pipe(
      tap(() => {
        console.log('usuario comprobado')
        this.idleSub = this.bnIdle.startWatching(30 * 60).pipe(first()).subscribe((isTimedOut: boolean) => {
          if (isTimedOut) {
            if (this.getUsuarioLogueado()) {
              this.logOut()
              const modalService = this.inj.get(MatDialog);
              const dialogRef = this.dialog.open(ModalMessageComponent, {
                data: "Su sesión ha expirado",
                width: "500px",
              });

            }
          }
        });
      }),
      catchError(this.handleError < any > ('AUTH SERVICE checKUser() ....', []))
    )
  }
  getToken() {
    let url = this.BASE_URL + this.GET_TOKEN;
    return this.http.post < any > (url, {
      appclient: this.CLIENT_NAME,
      apikey: this.API_KEY,
      settings: this.getSettings()
    }, {
      headers: {
        skip: "true"
      }
    }).pipe(
      tap(() => console.log('Token descargado')),
      catchError(this.handleError < any > ('AUTH SERVICE checUser() ....', []))
    )
  }

  private handleError < T > (operation = 'operation', result ? : T) {
    return (error: any): Observable < T > => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
