import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, startWith, throwIfEmpty } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { Buque } from '../../models/maestros';
import { ModalMessageComponent } from '../modal-message/modal-message.component';

@Component({
  selector: 'app-admin-buques',
  templateUrl: './admin-buques.component.html',
  styleUrls: ['./admin-buques.component.css']
})
export class AdminBuquesComponent implements OnInit {
  tituloFormulario = "Alta de embarcación"


  // datos maestros 
  embarcacionesFiltradas: Observable<any>;
  embarcaciones: any[];
  embarcacionesMaestras: any[];
  embarcacionCtrl = new FormControl();
  embarcacionesSubs: Subscription;

  cofradiasFiltradas: Observable<any>;
  cofradias: any[];
  cofradiaCtrl = new FormControl();
  cofradiasSubs: Subscription;

  estado = 1


  // datos formulario
  idCtrl = new FormControl();
  formularioId: number;

  nombreCtrl = new FormControl();
  formularioNombre: string;

  nfcCtrl = new FormControl();
  formularioNfc: string;

  codigoCtrl = new FormControl();
  formularioCodigo: string;

  matriculaCtrl = new FormControl();
  formularioMatricula: string;

  cfpoCtrl = new FormControl();
  formularioCfpo: string;

  tripulacionCtrl = new FormControl();
  formularioTripulacion: number;

  armadorCtrl = new FormControl();
  formularioArmador: string;

  observacionesCtrl = new FormControl();
  formularioObservaciones: string;

  entidadesCtrl = new FormControl();
  formularioEntidades: any[] = [];

  estadoCtrl = new FormControl();
  formularioEstado: number;

  formularioEstados: any[] = [{ 'id': 1, 'nombre': 'Activo' }, { 'id': 2, 'nombre': 'Baja' }];

  dataSource;

  displayedColumns: string[] = ["nombre", "matricula", "armador" ,"editar", "borrar"];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private api: ApiService, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // console.clear();
    console.log("INIT ADMIN BUQUES .... ");

    // INICIO DE EMBARCACIONES 
    this.getEmbarcaciones();

    // SETEO DE COFRADIAS
    this.cofradiasSubs = this.api.getCofradias().subscribe(
      (data: any) => {
        if (data.exito) {
          this.cofradias = data.cofradias;
          console.log('cofradias', this.cofradias);
          this.cofradiasFiltradas = this.cofradiaCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarCofradias(value))
            );
          console.log('cofradias filtradas', this.cofradiasFiltradas);
        }
      },
      (error) => {
        console.log(
          "LISTA CONTROLES ngOnInit() getCofradias error .... ", error);
      }
    );

    // INICIO DE FORMULARIO 
    this.idCtrl.disable();
    this.codigoCtrl.disable();
  }

  // FUNCIONES DE FILTRO 
  filtrarEmbarcaciones(value: string) {
    const filterValue = value.toLowerCase();
    return this.embarcaciones.filter(embarcacion => embarcacion.nombre.toLowerCase().includes(filterValue));
  }

  filtrarCofradias(value: string) {
    const filterValue = value.toLowerCase();
    return this.cofradias.filter(cofradia => cofradia.nombre.toLowerCase().includes(filterValue));
  }

  // seleccionar entidades

  selectEntidad(entidad: any) {
    console.log(entidad);

    this.formularioEntidades = [];
    this.formularioEntidades.push(entidad);
  }


  checkCofradias() {
    let aux = this.formularioEntidades.filter(e => e.tipo === 1);
    if (aux) {
      return true;
    }
    return false;
  }

  cancelar() {
    this.formularioId = null;
    this.formularioNombre = null;
    this.formularioNfc = null;
    this.formularioMatricula = null;
    this.formularioCodigo = null;
    this.formularioCfpo = null;
    this.formularioTripulacion = null;
    this.formularioArmador = null;
    this.formularioEntidades = [];

    this.entidadesCtrl.setValue(null)
    this.formularioObservaciones = null;

    this.estadoCtrl.setValue(null);
    this.formularioEstado = null;
    this.tituloFormulario = "Alta de embarcación"
  }

  guardarBuque() {
    let buque = {};

    if (!this.formularioNombre) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establercer un nombre del buque ",
        width: '500px',
      });
      // alert("Debe establercer un nombre del buque ")
      return;
    }

    if (!this.formularioMatricula) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establer un matrícula",
        width: '500px',
      });
      // alert("Debe establer un matrícula");
      return;
    }

    if (this.formularioTripulacion && !parseInt(this.formularioTripulacion.toString())) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "La tripulación debe ser un número entero",
        width: '500px',
      });
      // alert("La tripulación debe ser un número entero");
      return;
    }

    if (this.formularioTripulacion && this.formularioTripulacion <= 0) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "La tripulación debe ser 1 o más",
        width: '500px',
      });
      // alert("La tripulación debe ser 1 o más");
      return;
    }

    // if (!this.formularioEntidades) {
    //   const dialogRef = this.dialog.open(ModalMessageComponent, {
    //     data: "Debe seleccionar una entidad",
    //     width: '500px',
    //   });
    //   // alert("Debe seleccionar una entidad");
    //   return;
    // }

    if (!this.formularioEstado) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe seleccionar un estado",
        width: '500px',
      });
      // alert("Debe seleccionar un estado")
    }



    buque = {
      id: this.formularioId ? this.formularioId : null,
      nombre: this.formularioNombre.trim(),
      nfc: this.formularioNfc ? this.formularioNfc.trim() : null,
      matricula: this.formularioMatricula.trim(),
      codigo: this.formularioCodigo ? this.formularioCodigo : null,
      cfpo: this.formularioCfpo ? this.formularioCfpo.trim() : null,
      tripulacion: this.formularioTripulacion ? this.formularioTripulacion : null,
      armador: this.formularioArmador ? this.formularioArmador.trim() : null,
      cofradias: this.formularioEntidades.length != 0 ? this.formularioEntidades : [this.cofradias[0]],
      estado: this.formularioEstado ? this.formularioEstado : 1,
      usuario: JSON.parse(localStorage.getItem('usuario'))

    }

    console.log("Buque que se va  enviar .... ", buque);

    this.api.saveEmbarcacion(buque).subscribe(
      (data: any) => {
        console.log("SAVE EMBARCACION data .... ", data);
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getEmbarcaciones();
          this.embarcacionCtrl.setValue('')
        }

      },
      error => {
        console.log("SAVE EMBARCACION error .... ", error);
      }
    )
  }


  // OPERACIONES LISTA 
  // seleccionar buque
  selectEmbarcacionFromAutocomplete(embarcacion) {

    this.embarcaciones = this.embarcaciones.filter(
      (e) => e.nombre.toUpperCase() === embarcacion
    );
    this.dataSource = new MatTableDataSource<any>(this.embarcaciones);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setFiltroEstado(estado) {
    this.estado = estado
    if (estado === 1 || estado === 2) {
      this.embarcaciones = this.embarcacionesMaestras.filter(
        e => e.estado === estado
      )
    }

    if (estado === 0) {
      this.embarcaciones = this.embarcacionesMaestras;
    }

    this.dataSource = new MatTableDataSource<any>(this.embarcaciones);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  // limpiar filtro 
  resetearFiltro(event) {
    if (event.target.value.length <= 3) {
      this.embarcaciones = this.embarcacionesMaestras;
      this.dataSource = new MatTableDataSource<any>(this.embarcaciones);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  onEnter(evt: any) {
    if (evt.source.selected) {
    }
  }

  // operaciones
  editarEmbarcacion(buque) {
    this.tituloFormulario = "Modificación de embarcación"

    console.log("Modificación de embarcación  .... ", buque);

    this.formularioId = buque.id;
    this.formularioNombre = buque.nombre;
    this.formularioNfc = buque.nfc;
    this.formularioMatricula = buque.matricula;
    this.formularioCodigo = buque.codigo;
    this.formularioCfpo = buque.cfpo;
    this.formularioTripulacion = buque.tripulacion;
    this.formularioArmador = buque.armador;
    this.formularioEntidades = buque.cofradias;
    this.entidadesCtrl.setValue(buque.cofradias[0].id)
    this.formularioObservaciones = buque.observaciones;

    this.estadoCtrl.setValue(buque.estado);
    this.formularioEstado = buque.estado;
  }

  borrarEmbarcacion(data) {
    this.api.deleteEmbarcacion(data.id).subscribe(
      (data: any) => {
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getEmbarcaciones();
          this.embarcacionCtrl.setValue('')
        }
      },
      (error) => {
        console.log("BORRAR EMBARCACACION error .... ", error);

      }
    )
  }

  activarEmbarcacion(data) {
    data.estado = 1
    this.api.saveEmbarcacion(data).subscribe(
      (data: any) => {
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getEmbarcaciones();
          this.embarcacionCtrl.setValue('')
        }
      },
      (error) => {
        console.log("ACTIVAR EMBARCACACION error .... ", error);

      }
    )
  }

  getEmbarcaciones(params = 0) {
    this.embarcacionesSubs = this.api.getEmbarcacionesMaestras(params).subscribe(
      (data: any) => {
        if (data.exito) {
          this.embarcaciones = data.buques;
          this.embarcacionesMaestras = data.buques;
          console.log('embarcaciones ', this.embarcaciones);

          this.dataSource = new MatTableDataSource<any>(this.embarcaciones);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.embarcacionesFiltradas = this.embarcacionCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarEmbarcaciones(value))
            )
          console.log('embarcaciones filtradas', this.embarcacionesFiltradas);
          this.setFiltroEstado(this.estado)
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getEmbarcaciones error .... ", error
        );
      }
    );

  }

  ngOnDestroy() {
    this.embarcacionesSubs.unsubscribe();
  }
}