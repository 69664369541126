<mat-toolbar class="main-header">
    <div class="main-header-titulo">
        <img src="assets/logos/logo_blanco_sinletra.png" alt="logo" width="25vw" height="35vh">&nbsp;&nbsp;<span>{{ title }} </span>
    </div>
    <!-- <div class="main-header-usuario-icon" (click)="logOut()">
        <mat-icon class="main-header-icon">keyboard_backspace</mat-icon> &nbsp; <span> Volver </span>
    </div> -->

    <div class="main-header-usuario-icon" (click)="logOut()">
        <!-- <span> {{ usuarioLogueado.nombreCompleto }} </span> &nbsp; | &nbsp; -->
        <span> Salir </span>&nbsp;<mat-icon>exit_to_app</mat-icon>
        
    </div>

</mat-toolbar>
<div class="main-container">

    <mat-drawer-container class="contenedor">
        <mat-drawer mode="side" opened class="aside">
            <ul>
                <!-- <li><a routerLink="controles" routerLinkActive="active"> Lista controles </a></li> -->

                <li><a ><button mat-raised-button routerLink="/main/controles" routerLinkActive="active"
                            class="btn-custom-azul">Lista controles</button>
                    </a></li>

                <li *ngIf="usuarioLogueado.rol === 1"><a><button mat-raised-button routerLink="/main/buque" routerLinkActive="active"
                            class="btn-custom-azul">Gestión buques</button></a>
                </li>

                <li *ngIf="usuarioLogueado.rol === 1"><a><button mat-raised-button routerLink="/main/especie" routerLinkActive="active"
                    class="btn-custom-azul">Gestión especies</button></a>
                </li>
                <li *ngIf="usuarioLogueado.rol === 1"><a><button mat-raised-button routerLink="/main/banco" routerLinkActive="active"
                    class="btn-custom-azul">Gestión bancos</button></a>
                </li>
                <li *ngIf="usuarioLogueado.rol === 1"><a><button mat-raised-button routerLink="/main/usuario" routerLinkActive="active"
                    class="btn-custom-azul">Gestión usuarios</button></a>
                </li>
            </ul>
        </mat-drawer>
        <mat-drawer-content class="contenido">
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>

</div>