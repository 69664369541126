import { OperationsService } from './../../services/operations.service';
import { CanActivate } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { EspecieControl, Banco } from './../../models/maestros';
import { ApiService } from 'src/app/services/api.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-especie',
  templateUrl: './modal-especie.component.html',
  styleUrls: ['./modal-especie.component.css']
})
export class ModalEspecieComponent implements OnInit {

  titulo: string;
  especieSeteada: any;
  index: number;
  formerId:number;

  // autocomplete especies
  especiesFiltradas: Observable<any>;
  especies: any[];
  especieCtrl = new FormControl();
  especiesSubs: Subscription;


  // autocomplete bancos
  bancosFiltrados: Observable<any>;
  bancos: any[];
  bancoCtrl = new FormControl();
  bancosSubs: Subscription;
  bancoSeleccionado: Banco;

  cantidadCtrl = new FormControl();
  cantidadSeleccionada: number;

  constructor(
    public dialogRef: MatDialogRef<ModalEspecieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private operations: OperationsService

  ) {
    [this.titulo, this.index, this.especieSeteada] = data;
  }

  ngOnInit(): void {
    // [this.titulo, this.index, this.especie] = this.data;
    console.log("MODAL ESPECIE ngOnInit() ", this.titulo, this.index, this.especieSeteada)
    if (this.especieSeteada) {
      this.especieCtrl.setValue(this.especieSeteada.nombre);
      this.bancoCtrl.setValue(this.especieSeteada.banco.nombre);
      this.cantidadCtrl.setValue(this.especieSeteada.cantidad);
      this.especieSeteada.index = this.index;
      this.bancoSeleccionado = this.especieSeteada.banco;
      this.cantidadSeleccionada = this.especieSeteada.cantidad;
      this.formerId = this.especieSeteada.id;
    }
    if (this.index || this.index === 0) {
      this.especieSeteada.index = this.index;
    }

    // setear especies
    this.especiesSubs = this.api.getEspecies(1).subscribe(
      (data: any) => {
        if (data.exito) {
          this.especies = data.especies;
          this.especiesFiltradas = this.especieCtrl.valueChanges.pipe(
            startWith(''),
            map(e => e ? this.filtrarItems(e, this.especies) : this.especies.slice())
          )
        }
      }, error => { console.log("MODAL ESPECIES ngOnInit() getEspecies error .... ", error); }
    );

    // setear bancos
    this.bancosSubs = this.api.getBancos().subscribe(
      (data: any) => {
        // TODO refactorizar metodo en  api
        if (data.exito) {
          this.bancos = data.bancos;
          this.bancosFiltrados = this.bancoCtrl.valueChanges
            .pipe(
              startWith(''),
              map(b => b ? this.filtrarItems(b, this.bancos) : this.bancos.slice())
            )
        }
      }, error => { console.log("MODAL ESPECIES ngOnInit() getBancos error .... ", error); }
    );


  } // fin onInit()

  ngOnDestroy() {

  }

  private filtrarItems(value: string, items: any[]): any {
    const filterValue = value.toLowerCase();
    return items.filter(item => item.nombre.toLowerCase().indexOf(filterValue) === 0);
  }


  onEnter(evt: any) {
    if (evt.source.selected) {
    }
  }

  selectEspecie(especie) {
    this.especieSeteada = especie;
    this.especieSeteada.index = this.index;
    this.especieSeteada.banco = this.bancoSeleccionado;
    this.especieSeteada.cantidad = this.cantidadSeleccionada;
    console.log("SELECT ESPECIE ", this.especieSeteada)
  }

  selectBanco(banco) {
    this.especieSeteada.banco = banco;
    this.bancoSeleccionado = banco;
    console.log("SELECT BANCO ", this.especieSeteada)
  }
  setCantidad(cantidad) {
    if (typeof cantidad === 'string') {
      cantidad = parseFloat(cantidad);
    }
    this.especieSeteada.cantidad = cantidad;
    this.cantidadSeleccionada = cantidad;
    console.log("SET CANTIDAD ", this.especieSeteada)
  }

  cancelar() {
    this.dialogRef.close();

  }

  guardarEspecie() {

    console.log("MODAL ESPECIE guardarEspecie() especieSeteada .... ", this.especieSeteada);

    if (!this.especieSeteada) {
      alert("No se ha establecido un especie")
      return;
    }
    if (!this.especieSeteada.banco) {
      alert("No se ha establecido el banco")
      return;
    }
    if (!this.especieSeteada.cantidad) {
      alert("No se ha establecido la candidad")
    }
    if (this.index) {
      this.especieSeteada.index = this.index;
    }

    this.especieSeteada.former = this.formerId;
    this.operations.especieSeteada.emit(this.especieSeteada);
  }
}
