import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ModalMessageComponent } from '../modal-message/modal-message.component';

@Component({
  selector: 'app-admin-especies',
  templateUrl: './admin-especies.component.html',
  styleUrls: ['./admin-especies.component.css']
})
export class AdminEspeciesComponent implements OnInit {

  tituloFormulario = "Alta de especie"


  // datos maestros 
  especiesFiltradas: Observable<any>;
  especies: any[];
  especiesMaestras: any[];
  especieCtrl = new FormControl();
  especiesSubs: Subscription;

  cofradiasFiltradas: Observable<any>;
  cofradias: any[];
  cofradiaCtrl = new FormControl();
  cofradiasSubs: Subscription;

  tiposFiltrados: Observable<any>;
  tipos: any[];
  tipoCtrl = new FormControl();
  tiposSubs: Subscription;
  formularioTipo: string;


  // datos formulario
  idCtrl = new FormControl();
  formularioId: number;

  nombreCtrl = new FormControl();
  formularioNombre: string;

  nReducidoCtrl = new FormControl();
  formularioNReducido: string;

  cupoCtrl = new FormControl();
  formularioCupo: string;

  nCienCtrl = new FormControl();
  formularioNCien: string;

  faoCtrl = new FormControl();
  formularioFao: string;

  observacionesCtrl = new FormControl();
  formularioObservaciones: string;

  estadoCtrl = new FormControl();
  formularioEstado: number;

  formularioEstados: any[] = [{ 'id': 1, 'nombre': 'Activo' }, { 'id': 2, 'nombre': 'Baja' }];

  dataSource;

  displayedColumns: string[] = ["nombre", "fao", "cientifico" , "editar", "borrar"];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  estado: any = 1;


  constructor(
    private api: ApiService, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // console.clear();
    console.log("INIT ADMIN BUQUES .... ");

    // INICIO DE ESPECIEES 
    this.getEspecies();

    // SETEO DE TIPOS
    this.tiposSubs = this.api.getTipos().subscribe(
      (data: any) => {
        if (data.exito) {
          this.tipos = data.tipos;
          console.log('tipos', this.tipos);
          // this.tiposFiltrados = this.tipoCtrl.valueChanges
          //   .pipe(
          //     startWith(''),
          //     map((value) => this.filtrarTipos(value))
          //   );
          // console.log('tipos filtrados', this.tiposFiltrados);
        }
      },
      (error) => {
        console.log(
          "LISTA CONTROLES ngOnInit() getTipos error .... ", error);
      }
    );

    // INICIO DE FORMULARIO 
    this.idCtrl.disable();
  }

  // FUNCIONES DE FILTRO 
  filtrarEspecies(value: string) {
    const filterValue = value.toLowerCase();
    return this.especies.filter(especie => especie.nombre.toLowerCase().includes(filterValue));
  }

  filtrarCofradias(value: string) {
    const filterValue = value.toLowerCase();
    return this.cofradias.filter(cofradia => cofradia.nombre.toLowerCase().includes(filterValue));
  }


  selectTipos(tipo: any) {
    console.log(tipo);

    this.formularioTipo = tipo;
  }

  cancelar() {
    this.formularioId = null;
    this.formularioNombre = null;
    this.formularioNReducido = null;
    this.formularioNCien = null;
    this.formularioCupo = null;
    this.formularioFao = null;
    this.formularioTipo = null;
    this.tipoCtrl.setValue(null);
    this.formularioObservaciones = null;

    this.estadoCtrl.setValue(null);
    this.formularioEstado = null;
    this.tituloFormulario = "Alta de especie"
  }

  guardarEspecie() {
    let especie = {};

    if (!this.formularioNombre) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establercer un nombre de la especie ",
        width: '500px',
      });
      // alert("Debe establercer un nombre del especie ")
      return;
    }

    if (!this.formularioNCien) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establer un nombre científico",
        width: '500px',
      });
      // alert("Debe establer un matrícula");
      return;
    }

    if (!this.formularioFao) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe establer un fao",
        width: '500px',
      });
      // alert("Debe establer un matrícula");
      return;
    }

    if (!this.formularioTipo) {
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: "Debe seleccionar un tipo",
        width: '500px',
      });
      // alert("Debe seleccionar una entidad");
      return;
    }



    especie = {
      id: this.formularioId ? this.formularioId : null,
      nombre: this.formularioNombre.trim(),
      nreducido: this.formularioNReducido.trim(),
      nciencia: this.formularioNCien.trim(),
      cupo: this.formularioCupo ? this.formularioCupo : null,
      fao: this.formularioFao ? this.formularioFao.trim() : null,
      tipo: this.formularioTipo,
      estado: this.formularioEstado ? this.formularioEstado : 1,
      usuario: JSON.parse(localStorage.getItem('usuario'))

    }

    console.log("Especie que se va  enviar .... ", especie);

    this.api.saveEspecie(especie).subscribe(
      (data: any) => {
        console.log("SAVE ESPECIE data .... ", data);
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getEspecies();
          this.especieCtrl.setValue('')
        }

      },
      error => {
        console.log("SAVE ESPECIE error .... ", error);
      }
    )
  }


  // OPERACIONES LISTA 
  // seleccionar especie
  selectEspecieFromAutocomplete(especie) {

    this.especies = this.especies.filter(
      (e) => e.nombre.toUpperCase() === especie
    );
    this.dataSource = new MatTableDataSource<any>(this.especies);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setFiltroEstado(estado) {
    this.estado = estado
    if (estado === 1 || estado === 2) {
      this.especies = this.especiesMaestras.filter(
        e => e.estado === estado
      )
    }

    if (estado === 0) {
      this.especies = this.especiesMaestras;
    }

    this.dataSource = new MatTableDataSource<any>(this.especies);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  // limpiar filtro 
  resetearFiltro(event) {
    if (event.target.value.length <= 3) {
      this.especies = this.especiesMaestras;
      this.dataSource = new MatTableDataSource<any>(this.especies);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  onEnter(evt: any) {
    if (evt.source.selected) {
    }
  }

  // operaciones
  editarEspecie(especie) {
    this.tituloFormulario = "Modificación de especie"

    console.log("Modificación de especie  .... ", especie);

    this.formularioId = especie.id;
    this.formularioNombre = especie.nombre;
    this.formularioNReducido = especie.nreducido;
    this.formularioNCien = especie.cientifico;
    this.formularioCupo = especie.cupo;
    this.formularioFao = especie.fao;
    this.formularioTipo = especie.tipo;
    this.tipoCtrl.setValue(especie.tipo)
    // this.formularioTripulacion = especie.tripulacion;
    // this.formularioArmador = especie.armador;
    // this.formularioEntidades = especie.cofradias;
    // this.entidadesCtrl.setValue(especie.cofradias[0].id)
    // this.formularioObservaciones = especie.observaciones;

    this.estadoCtrl.setValue(especie.estado);
    this.formularioEstado = especie.estado;
  }

  borrarEspecie(data) {
    this.api.deleteEspecie(data.id).subscribe(
      (data: any) => {
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getEspecies();
          this.especieCtrl.setValue('')
        }
      },
      (error) => {
        console.log("BORRAR ESPECIE error .... ", error);

      }
    )
  }

  activarEspecie(data) {
    data.estado = 1
    this.api.saveEspecie(data).subscribe(
      (data: any) => {
        if (data.exito) {
          const dialogRef = this.dialog.open(ModalMessageComponent, {
            data: data.msg,
            width: '500px',
          });
          this.cancelar();
          this.getEspecies();
          this.especieCtrl.setValue('')
        }
      },
      (error) => {
        console.log("ACTIVAR ESPECIE error .... ", error);

      }
    )
  }

  getEspecies(params=0) {
    this.especiesSubs = this.api.getEspecies(params).subscribe(
      (data: any) => {
        if (data.exito) {
          this.especies = data.especies;
          this.especiesMaestras = data.especies;
          console.log('especies ', this.especies);

          this.dataSource = new MatTableDataSource<any>(this.especies);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.especiesFiltradas = this.especieCtrl.valueChanges
            .pipe(
              startWith(''),
              map((value) => this.filtrarEspecies(value))
            )
          console.log('especies filtradas', this.especiesFiltradas);
          this.setFiltroEstado(this.estado)
        }
      },
      (error) => {
        console.log("LISTA CONTROLES ngOnInit() getEspecies error .... ", error
        );
      }
    );

  }

  ngOnDestroy() {
    this.especiesSubs.unsubscribe();
  }

}
