<div class="wrapper">
     <mat-card>
          <mat-card-header>
               <mat-card-title>
                    Informacion asociada a {{control.embarcacion.nombre}} ({{control.embarcacion.matricula}}),
                    {{fecha}}
               </mat-card-title>
          </mat-card-header>
          <mat-card-content>
               <form class="formulario-opciones" autocomplete="off" (keydown.enter)="$event.preventDefault()">
                    <mat-card>
                         <mat-card-content>
                              <div class="header">
                                   <mat-form-field
                                        class="no-padding-bottom mat-input-disabled-custom datos-identificacion"
                                        appearance="outline">
                                        <mat-label>Identificador</mat-label>
                                        <input matInput [value]="control.id" disabled>
                                   </mat-form-field>

                                   <mat-form-field
                                        class="no-padding-bottom mat-input-disabled-custom datos-identificacion"
                                        appearance="outline">
                                        <mat-label>Barco</mat-label>
                                        <input matInput [value]="control.embarcacion.nombre" disabled>
                                   </mat-form-field>

                                   <mat-form-field
                                        class="no-padding-bottom mat-input-disabled-custom datos-identificacion"
                                        appearance="outline">
                                        <mat-label>Fecha</mat-label>
                                        <input matInput [value]="fecha" disabled>
                                   </mat-form-field>

                                   <mat-form-field appearance="outline" class="datos-cabecera">
                                        <mat-label>Tripulantes</mat-label>
                                        <input class="tripulantes" type="number" matInput #tripulantes
                                             [(ngModel)]="control.tripulantes" [ngModelOptions]="{standalone: true}">
                                   </mat-form-field>

                                   <mat-form-field appearance="outline" class="datos-cabecera">
                                        <mat-label>Hora Inicio</mat-label>
                                        <input class="hora" type="time" #horaIni matInput [(ngModel)]="control.horaIni"
                                             [ngModelOptions]="{standalone: true}">
                                   </mat-form-field>

                                   <mat-form-field appearance="outline" class="datos-cabecera hidden">
                                        <mat-label>Hora Fin</mat-label>
                                        <input class="hora" type="time" #hora matInput [(ngModel)]="control.hora"
                                             [ngModelOptions]="{standalone: true}">
                                   </mat-form-field>

                                   <mat-form-field appearance="outline"
                                        class="no-padding-bottom mat-input-disabled-custom datos-cabecera hidden">
                                        <mat-label>Cofradía destino</mat-label>
                                        <input type="text" matInput #inputCofradia [value]="control.cofradia.nombre"
                                             [formControl]="cofradiaCtrl">
                                        <mat-icon matSuffix fontSet="fas" fontIcon="fa-home" class="formulario">
                                        </mat-icon>
                                        <!-- 
                                        <mat-autocomplete autoActiveFirstOption #cofradia="matAutocomplete">
                                             <mat-option *ngFor="let cofradia of cofradiasFiltradas | async"
                                                  [value]="cofradia.nombre"
                                                  (onSelectionChange)="inputCofradia.value != undefined && onEnter($event); selectCofradiaFromAutocomplete(cofradia)">
                                                  <span>{{cofradia.nombre}}</span>
                                             </mat-option>
                                        </mat-autocomplete> -->
                                   </mat-form-field>
                                   <!-- <mat-form-field appearance="outline">
                                        <mat-label>Cofradía destino</mat-label>
                                        <input type="text" matInput #inputCofradia [matAutocomplete]="cofradia"
                                             [value]="control.cofradia.nombre" [formControl]="cofradiaCtrl">
                                        <mat-icon matSuffix fontSet="fas" fontIcon="fa-home" class="formulario"></mat-icon>
                                        <mat-autocomplete autoActiveFirstOption #cofradia="matAutocomplete">
                                             <mat-option *ngFor="let cofradia of cofradiasFiltradas | async"
                                                  [value]="cofradia.nombre"
                                                  (onSelectionChange)="inputCofradia.value != undefined && onEnter($event); selectCofradiaFromAutocomplete(cofradia)">
                                                  <span>{{cofradia.nombre}}</span>
                                             </mat-option>
                                        </mat-autocomplete>
                                   </mat-form-field> -->
                              </div>
                         </mat-card-content>
                    </mat-card>
               </form>
               <div class="especies">
                    <mat-card>
                         <mat-card-header>
                              <mat-card-title>
                                   Relación de especies
                              </mat-card-title>
                         </mat-card-header>
                         <mat-card-content>
                              <table #lista_especies mat-table class="lista-especies" [dataSource]="dataSource">
                                   <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef>
                                             <mat-icon>local_offer</mat-icon> ESPECIE
                                        </th>
                                        <td mat-cell *matCellDef="let especie"> {{especie.nombre}} </td>
                                   </ng-container>
                                   <ng-container matColumnDef="fao">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">
                                             <mat-icon>style</mat-icon>
                                             FAO
                                        </th>
                                        <td mat-cell *matCellDef="let especie" class="text-center"> {{especie.fao}}
                                        </td>
                                   </ng-container>
                                   <ng-container matColumnDef="cantidad">
                                        <th mat-header-cell *matHeaderCellDef class="text-right">
                                             <mat-icon>widgets</mat-icon> KG
                                        </th>
                                        <td mat-cell *matCellDef="let especie" class="text-right">
                                             {{especie.cantidad}} </td>
                                   </ng-container>
                                   <ng-container matColumnDef="banco">
                                        <th mat-header-cell *matHeaderCellDef class="banco">
                                             <mat-icon>place</mat-icon> BANCO
                                        </th>
                                        <td mat-cell *matCellDef="let especie" class="banco">
                                             {{especie.banco.nombre}}
                                        </td>
                                   </ng-container>

                                   <ng-container matColumnDef="editar">
                                        <th mat-header-cell *matHeaderCellDef class="icon-column"> </th>
                                        <td mat-cell *matCellDef="let row, let i = index"
                                             (click)="editarEspecie(row, i)" class="td-icon td-icon-blue">
                                             <button mat-raised-button class="button-blue" title="Editar especie">
                                                  <mat-icon>edit</mat-icon>
                                             </button>
                                        </td>
                                   </ng-container>

                                   <ng-container matColumnDef="borrar">
                                        <th mat-header-cell *matHeaderCellDef class="icon-column"> </th>
                                        <td mat-cell *matCellDef="let row, let i = index"
                                             (click)="borrarEspecie(row, i)" title="Borrar expecie">
                                             <button mat-raised-button class="button-red">
                                                  <mat-icon>delete_forever</mat-icon>
                                             </button>
                                        </td>
                                   </ng-container>
                                   <tr mat-header-row *matHeaderRowDef="columnas"></tr>
                                   <tr mat-row *matRowDef="let row; columns: columnas;"></tr>
                              </table>
                         </mat-card-content>
                    </mat-card>
               </div>
          </mat-card-content>
          <mat-card-actions class="botonera">
               <button mat-button mat-raised-button class="button-green" (click)="agregarEspecie()">
                    <mat-icon>add_circle_outline</mat-icon> Agregar especie
               </button>
               <button mat-button mat-raised-button class="button-red" (click)="cancelar()">
                    <mat-icon>clear</mat-icon> Cancelar
               </button>
               <button mat-button mat-raised-button class="button-blue" (click)="guardarControl()">
                    <mat-icon>done</mat-icon> Guardar
               </button>
          </mat-card-actions>

     </mat-card>
</div>