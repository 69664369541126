import { MainModule } from './main/main.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material.module';
import { AuthService } from './services/auth.service';

import { AuthGuard } from './guards/auth-guads.service';
import { JwtInterceptorService } from './interceptors/jwt-interceptor.service';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core/';
import { BypassComponent } from './bypass/bypass.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MyPaginator } from './utils/my-paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import * as moment from 'moment';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
  });
}
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BypassComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatNativeDateModule,
    MainModule,
    MatFormFieldModule,
    MatInputModule 
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    AuthService,
    AuthGuard,
    JwtInterceptorService, {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MyPaginator },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
